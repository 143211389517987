import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BlogPost = () => {
    const { id } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(`/blogs/${id}.html`);
            const text = await response.text();
            setContent(text);
        };
        fetchContent();
    }, [id]);

    return (
        <div className="blog-post" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
};

export default BlogPost;
