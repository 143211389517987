import React, { useState, useEffect, useCallback } from 'react';
import { AiFillDelete } from "react-icons/ai";
import { getUserData, removeItem, addToUserAttribute, addInitialEmails } from "../../components/awsAuth";
import { useAuth0 } from "@auth0/auth0-react";
import GoogleOAuthSendEmails from './GoogleOAuthSendEmails';
import RefreshSendEmails from './RefreshSendEmails';
import { Link } from 'react-router-dom';
import OnSuccessPopup from '../../components/OnSuccessPopup';
import OnUpgradePopup from '../../components/OnUpgradePopup';
import { FiCopy } from "react-icons/fi";
import SendSettings from '../../components/dashboard/sendSettings';
import InfoTooltip from '../../components/InfoTooltip'; // Import the new InfoTooltip component

const { DynamoDBClient, UpdateItemCommand } = require("@aws-sdk/client-dynamodb");

export default function Approve(props) {
    const { user } = useAuth0();
    const [emailInfo, setEmailInfo] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedEmailType, setSelectedEmailType] = useState('coffee');
    const [selectAll, setSelectAll] = useState(true);
    const [userParagraphCoffeeChat, setUserParagraphCoffeeChat] = useState('');
    const [userSubjectLine, setUserSubjectLine] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [copyStatus, setCopyStatus] = useState({});
    const [sentOrToSend, setSentOrToSend] = useState('toSend');
    const [autoSend, setAutoSend] = useState();
    const [pageValue, setPageValue] = useState(0);
    const [sentPageValue, setSentPageValue] = useState(0);
    const emailsPerPage = 5;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [refreshToken, setRefreshToken] = useState(null);
    const [unsavedChangesNotif, setUnsavedChangesNotif] = useState(false);
    const [showEmailConfirmationPopup, setEmailConfirmationPopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [userSubscription, setUserSubscription] = useState("0");
    const [numEmails, setNumEmails] = useState(0);
    const [showAuthMessage, setShowAuthMessage] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    const awsCredentials = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    };

    const dynamoDBClient = new DynamoDBClient({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });

    const variables = [
        { key: '[their_first_name]', description: "Their first name" },
        { key: '[relevant_industry]', description: "Relevant industry" },
        { key: '[their_company]', description: "Their company" },
    ];

    const copyToClipboard = async (text, variableKey) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopyStatus(prevStatus => ({ ...prevStatus, [variableKey]: true }));

            setTimeout(() => {
                setCopyStatus(prevStatus => ({ ...prevStatus, [variableKey]: false }));
            }, 1000);
        } catch (err) {
            console.error('Failed to copy!', err);
        }
    };

    const onEmailsSent = async (statusCode) => {
        if (user?.email) {
            try {

                if(statusCode === 401){
                    setShowAuthMessage(true);
                    setEmailConfirmationPopup(false);
                    setTimeout(() => setShowAuthMessage(false), 5000);
                }else{
                    if(numEmails === 1){
                        await addInitialEmails(user?.email);
                    }
    
                    let updatedEmails = await getUserData(user?.email);
                    updatedEmails = updatedEmails.emails_to_send;
                    setNumEmails(updatedEmails.length);
    
                    setEmailInfo(updatedEmails);
                    setEmailConfirmationPopup(false);
                    setSelectedEmails(updatedEmails.filter(email => email.email_status === 'Confirmed' || email.email_status === 'Unconfirmed'));
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 3000);
                }
            } catch (error) {
                console.error("Failed to fetch updated emails:", error);
            }
        }
    };

    useEffect(() => {
        const fetchEmails = async () => {
            if (user?.email) {
                try {
                    let userData = await getUserData(user?.email);
                    setAutoSend(userData.auto_send ?? false);
                    setNumEmails(userData.emails_to_send.length);
                    setUserParagraphCoffeeChat(userData.paragraph_coffee_chat ?? '');
                    setUserSubjectLine(userData.paragraph_subject_line ?? '');
                    setRefreshToken(userData.refresh_token ?? null);
                    setUserSubscription(userData.subscription);
                    let initialEmails = userData.emails_to_send ?? [];
                    setEmailInfo(initialEmails);

                    setSelectedEmails(initialEmails.filter(email => email.email_status === 'Confirmed' || email.email_status === 'Unconfirmed'));
                } catch (error) {
                    console.log("Failed to fetch emails:", error);
                }
            }
        };
        fetchEmails();
    }, [user?.email]);

    const toggleAllEmails = useCallback(() => {
        const filteredEmails = searchQuery 
            ? emailInfo.filter(email =>
                email.recipient_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                email.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                email.company_email.toLowerCase().includes(searchQuery.toLowerCase())
            )
            : emailInfo;
    
        if (!selectAll) {
            setSelectedEmails(filteredEmails.filter(email => email.email_status === 'Unconfirmed'));
        } else {
            setSelectedEmails([]);
        }
        setSelectAll(!selectAll);
    }, [emailInfo, selectAll, searchQuery]);
    
    

    const toggleEmailSelection = (email, isChecked) => {
        if (isChecked) {
            setSelectedEmails(prev => [...prev, email]);
        } else {
            setSelectedEmails(prev => prev.filter(selectedEmail => selectedEmail.company_email !== email.company_email));
        }
    };

    const iteratePageValue = (direction) => {
        if (direction === 'next') {
            setPageValue(pageValue + 1);
        } else {
            setPageValue(pageValue - 1);
        }
    };

    const removeEmail = async (emailToRemove) => {
        setEmailInfo(prev => prev.filter(email => email.company_email !== emailToRemove.company_email));
        setSelectedEmails(prev => prev.filter(selectedEmail => selectedEmail.company_email !== emailToRemove.company_email));
        const clientEmail = user?.email;
        removeItem(clientEmail, emailToRemove.company_email);
        await addToUserAttribute(clientEmail, 'num_unsent_emails', -1);
    };

    const handleParagraphSubmitCoffeeChat = async (event) => {
        event.preventDefault();

        const params = {
            TableName: "internify",
            Key: {
                client_email: { S: user.email }
            },
            UpdateExpression: "set paragraph_coffee_chat = :p, paragraph_subject_line = :s",
            ExpressionAttributeValues: {
                ":p": { S: userParagraphCoffeeChat },
                ":s": { S: userSubjectLine }
            },
            ReturnValues: "UPDATED_NEW"
        };

        try {
            const command = new UpdateItemCommand(params);
            const response = await dynamoDBClient.send(command);
            console.log("Success", response);
            setShowSuccessMessage(true);
            setUnsavedChangesNotif(false);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            console.error(error);
            console.log("Error");
        }
    };

    const iterateSentPageValue = (direction) => {
        if (direction === 'next') {
            setSentPageValue(sentPageValue + 1);
        } else {
            setSentPageValue(sentPageValue - 1);
        }
    };

    const SentEmails = ({ emails, sentPageValue, emailsPerPage }) => {
        const paginatedSentEmails = emails
            .filter(email => email.email_status === 'Sent')
            .sort((a, b) => new Date(b.day_sent) - new Date(a.day_sent))
            .slice(sentPageValue * emailsPerPage, (sentPageValue + 1) * emailsPerPage);
    
        // Check if there are no emails to display
        if (emails.filter(email => email.email_status === 'Sent').length === 0) {
            return (
                <div className="text-center text-gray-500 mt-4">
                    You've sent all your emails! Check in on Tuesday to get new professionals to reach out to.
                </div>
            );
        }
    
        return (
            <div className=''>
                <div className="overflow-x-auto bg-white shadow mt-2">
                    <table className="min-w-full leading-normal bg-whitetable-fixed">
                        <thead>
                            <tr>
                                <th className="sticky top-0 px-4 py-2 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                    Name
                                </th>
                                <th className="sticky top-0 px-4 py-2 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                    Company
                                </th>
                                <th className="sticky top-0 px-4 py-2 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                    Email
                                </th>
                                <th className="sticky top-0 px-4 py-2 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                    Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {paginatedSentEmails.map((email, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 border-b border-gray-100 bg-white text-xs text-left">
                                        {email.recipient_name}
                                    </td>
                                    <td className="px-4 py-2 border-b border-gray-100 bg-white text-xs text-left">
                                        <a href={`https://www${email.company_email.substring(email.company_email.indexOf('@'))}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-50">
                                            {email.company_name}
                                        </a>
                                    </td>
                                    <td className="px-4 py-2 border-b border-gray-100 bg-white text-xs text-left">
                                        {email.company_email}
                                    </td>
                                    <td className="px-4 py-2 border-b border-gray-100 bg-white text-xs text-left">
                                        {email.day_sent ? (
                                            <>
                                                <div>
                                                    {new Date(email.day_sent).toLocaleDateString('en-US', {
                                                        year: '2-digit', month: 'numeric', day: 'numeric'
                                                    })}
                                                </div>
                                                <div>
                                                    {new Date(email.day_sent).toLocaleTimeString('en-US', {
                                                        hour: 'numeric', minute: '2-digit', hour12: true
                                                    })}
                                                </div>
                                            </>
                                        ) : 'Not Sent'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
    

    const filteredEmails = emailInfo.filter(email =>
        email.recipient_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.company_email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const DualEmailTable = React.memo(({ emails, selectedEmails, toggleEmailSelection, removeEmail }) => {
        const memoizedToggleEmailSelection = useCallback((email, isSelected) => {
            toggleEmailSelection(email, isSelected);
        }, [toggleEmailSelection]);

        const memoizedRemoveEmail = useCallback((email) => {
            removeEmail(email);
        }, [removeEmail]);


        return (
            <div className="overflow-y-scroll h-[271px] shadow" style={{ scrollbarWidth: "thin" }} data-tour="onboard-sendemail-1">
                <table className="min-w-full bg-white table-fixed">
                    <thead>
                        <tr>
                            <th className="sticky top-0 px-4 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Name
                            </th>
                            <th className="sticky top-0 px-4 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Company
                            </th>
                            <th className="sticky top-0 px-4 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Position
                            </th>
                            <th className="sticky top-0 px-4 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                <div className='flex items-center'>
                                    <input type="checkbox" className="accent-black scale-125 mr-3" checked={selectAll} onChange={toggleAllEmails} />
                                    Approve
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {emails.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed')
                            .slice(pageValue * emailsPerPage, (pageValue + 1) * emailsPerPage).map((email, index) => (
                                <tr key={index} {...(index === 0 ? {} : {})}>
                                    <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                        {email.recipient_name}
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                        <a href={`https://www${email.company_email.substring(email.company_email.indexOf('@'))}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-50">
                                            {email.company_name}
                                        </a>
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                        {email.role}
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                        <div className='flex'>
                                            <input
                                                type="checkbox"
                                                className="accent-black scale-100 mr-3 leading-tight checkbox-black-bg"
                                                checked={selectedEmails.some(selectedEmail => selectedEmail.company_email === email.company_email)}
                                                onChange={() => memoizedToggleEmailSelection(email, !selectedEmails.some(selectedEmail => selectedEmail.company_email === email.company_email))}
                                            />
                                            <button onClick={() => memoizedRemoveEmail(email)} className="text-red-500 hover:text-red-700 text-xl">
                                                <AiFillDelete />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                    
                </table>
                {
                    sentOrToSend === 'toSend' && filteredEmails.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed').length === 0 && (
                        <div className="flex flex-col items-center justify-center flex-grow text-center mt-14">
                            <p className="text-3xl font-medium text-gray-500">That's all for now!</p>
                            <p className="text-md text-gray-500 mt-2">Check back in on Tuesday to get more professionals to connect with</p>
                        </div>
                    )
                }
            </div>
        );
    });

    const totalPendingEmails = emailInfo.filter(email => email.email_status === 'Pending Verification').length;

    return (
        <div className="flex flex-col p-8">
            {
                showEmailConfirmationPopup && (
                    <div className='fixed inset-0 bg-black bg-opacity-75 z-[269] flex justify-center items-center' data-tour="onboard-sendemail-4">
                    <div className='relative px-12 py-10 bg-white rounded-xl'>
                        <button 
                            className='absolute top-4 right-4 text-black text-3xl'
                            onClick={() => setEmailConfirmationPopup(false)}
                        >
                            &times;
                        </button>
                        <h1 className='font-medium text-2xl text-center'>Confirm Sending Emails</h1>
                        <h1 className='text-md text-center mt-4 text-gray-500'>You are about to send {selectedEmails.length} emails. Are you sure you want to proceed?</h1>
                        <h1 className='text-md text-center mt-2 text-gray-500'>Click a button below to continue.</h1>
                        <div className='flex mt-8 w-full align-center justify-center'>
                            <button 
                                className='border-black border bg-black px-8 py-2 mr-10 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out' 
                                onClick={() => setEmailConfirmationPopup(false)}
                            >
                                Not yet
                            </button>
                            {refreshToken ? (
                                <RefreshSendEmails
                                    userEmail={user?.email}
                                    fullName={user?.fullName}
                                    allEmails={emailInfo}
                                    emailsToUpdate={selectedEmails}
                                    numToSend={selectedEmails.length}
                                    selectedEmailType={selectedEmailType}
                                    onEmailsSent={onEmailsSent}
                                />
                            ) : (
                                <GoogleOAuthSendEmails
                                    userEmail={user?.email}
                                    fullName={user?.fullName}
                                    allEmails={emailInfo}
                                    emailsToUpdate={selectedEmails}
                                    numToSend={selectedEmails.length}
                                    selectedEmailType={selectedEmailType}
                                />
                            )}
                        </div>
                    </div>
                </div>
                )
            }

            {
                showSuccessMessage ? (
                    <OnSuccessPopup showSuccessMessage={showSuccessMessage} successMessage={'Your profile has been updated.'}></OnSuccessPopup>
                ) : null
            }

            {
                showAuthMessage ? (
                    <OnUpgradePopup showMessage={showAuthMessage} message={'Error: Head to Settings to Authorize your email.'}></OnUpgradePopup>
                ) : null
            }

            <div className='flex-col w-full justify-center' style={{ gap: '50px' }}>

                <div className=' bg-white p-8 border border-gray-300 rounded-2xl w-full'>
                    <div className='w-full mb-8'>
                        <div className='flex w-full justify-between items-center'>
                            <div className='flex'>
                                <h1 className="font-medium text-3xl flex items-center">
                                    Emails to Send ({emailInfo.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed').length})
                                    <InfoTooltip text={"Here are all the contacts you can email by clicking Send Emails.\nYou'll get 5-50 new contacts to email weekly."} />
                                </h1>

                                <div className='flex items-center ml-6'>
                                    <button
                                        onClick={() => setSentOrToSend('toSend')}
                                        type="submit"
                                        className={`text-xs px-4 py-2 border rounded-md ${sentOrToSend === 'toSend' ? 'opacity-100 bg-black text-black bg-transparent border-black border' : 'text-gray-400 border-gray-300'} mr-4`}
                                    >
                                        Emails to send
                                    </button>
                                    <button
                                        onClick={() => setSentOrToSend('Sent')}
                                        type="submit"
                                        className={`text-xs px-4 py-2 border rounded-md ${sentOrToSend === 'Sent' ? 'opacity-100 bg-black text-black bg-transparent border-black border' : 'text-gray-400 border-gray-300'}`}
                                    >
                                        Sent email history
                                    </button>
                                </div>
                            </div>

                            <button 
                                className='border-black border bg-black px-8 py-2 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out' 
                                onClick={(e) => {
                                    setEmailConfirmationPopup(true);
                                }}
                                data-tour="onboard-sendemail-3"
                            >
                                Send Emails ({selectedEmails.length})
                            </button>

                        </div>

                        <input
                            type="text"
                            className="w-full p-2 mt-4 mb-2 border rounded-md text-sm"
                            placeholder="Search by name, company, or email..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {
                        sentOrToSend === 'toSend' ? (
                            <div>
                                <DualEmailTable
                                    emails={filteredEmails}
                                    selectedEmails={selectedEmails}
                                    toggleEmailSelection={toggleEmailSelection}
                                    removeEmail={removeEmail}
                                />
                                <div className='mt-6 w-full flex justify-between items-center'>
                                    <div>
                                        <span className="text-sm text-gray-500">
                                            Showing {(pageValue * emailsPerPage) + 1} - {Math.min((pageValue + 1) * emailsPerPage, filteredEmails.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed').length)} of {filteredEmails.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed').length}
                                        </span>
                                    </div>
                                    <div className='flex justify-end'>
                                        <button
                                            onClick={() => pageValue > 0 && iteratePageValue('prev')}
                                            type="button"
                                            className={`text-sm px-4 py-1 border rounded-md ${pageValue > 0 ? 'opacity-100 bg-black text-white border-black hover:bg-transparent hover:text-black' : 'opacity-50 bg-transparent text-gray-300 border-gray-300 cursor-not-allowed'} mr-4 duration-500 ease-in-out`}
                                        >
                                            ←
                                        </button>
                                        <button
                                            onClick={() => pageValue < Math.ceil(filteredEmails.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed').length / emailsPerPage) - 1 && iteratePageValue('next')}
                                            type="button"
                                            className={`text-sm px-4 py-1 border rounded-md ${pageValue < Math.ceil(filteredEmails.filter(email => email.email_status === 'Unconfirmed' || email.email_status === 'Confirmed').length / emailsPerPage) - 1 ? 'opacity-100 bg-black text-white border-black hover:bg-transparent hover:text-black' : 'opacity-50 bg-transparent text-gray-300 border-gray-300 cursor-not-allowed'} duration-500 ease-in-out`}
                                        >
                                            →
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <SentEmails
                                    emails={filteredEmails}
                                    sentPageValue={sentPageValue}
                                    emailsPerPage={emailsPerPage}
                                />
                                <div className='mt-6 w-full flex justify-between items-center'>
                                    <div>
                                        <span className="text-sm text-gray-500">
                                            Showing {(sentPageValue * emailsPerPage) + 1} - {Math.min((sentPageValue + 1) * emailsPerPage, filteredEmails.filter(email => email.email_status === 'Sent').length)} of {filteredEmails.filter(email => email.email_status === 'Sent').length}
                                        </span>
                                    </div>
                                    <div className='flex justify-end'>
                                        <button
                                            onClick={() => sentPageValue > 0 && iterateSentPageValue('prev')}
                                            type="button"
                                            className={`text-sm px-4 py-1 border rounded-md ${sentPageValue > 0 ? 'opacity-100 bg-black text-white border-black hover:bg-transparent hover:text-black' : 'opacity-50 bg-transparent text-gray-300 border-gray-300 cursor-not-allowed'} mr-4 duration-500 ease-in-out`}
                                        >
                                            ←
                                        </button>
                                        <button
                                            onClick={() => sentPageValue < Math.ceil(filteredEmails.filter(email => email.email_status === 'Sent').length / emailsPerPage) - 1 && iterateSentPageValue('next')}
                                            type="button"
                                            className={`text-sm px-4 py-1 border rounded-md ${sentPageValue < Math.ceil(filteredEmails.filter(email => email.email_status === 'Sent').length / emailsPerPage) - 1 ? 'opacity-100 bg-black text-white border-black hover:bg-transparent hover:text-black' : 'opacity-50 bg-transparent text-gray-300 border-gray-300 cursor-not-allowed'} duration-500 ease-in-out`}
                                        >
                                            →
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className='flex w-full' style={{ gap: '50px' }}>
                    <form className="section bg-white border border-gray-300 p-8 rounded-2xl"
                        onSubmit={handleParagraphSubmitCoffeeChat}
                        data-tour="onboard-setemail-1">

                        <div className='flex justify-between items-center w-full  mb-4'>
                            <h1 className="mr-2 font-medium text-3xl flex items-center">
                                Customize Template
                                <InfoTooltip text={"This section is where you'll create and customize your email template."} />
                            </h1>
                            <Link to='/settings/resume' className='text-md hover:text-gray-600 text-gray-400 p-2 rounded-md transition duration-300'>Add or edit resume</Link>
                        </div>

                        {unsavedChangesNotif && (
                        <p className="text-red-500 mt-2">You have unsaved changes*</p>
                        )}

                        <div data-tour="onboard-setemail-3">
                            <textarea
                                value={userSubjectLine}
                                onChange={(e) => {
                                    setUserSubjectLine(e.target.value);
                                    setUnsavedChangesNotif(true);
                                }}
                                placeholder="Type your subject line here..."
                                className="mt-2 p-2 border rounded w-full text-gray-500 text-sm"
                                style={{ height: '37px' }}
                            />

                            <textarea
                                value={userParagraphCoffeeChat}
                                onChange={(e) => {
                                    setUserParagraphCoffeeChat(e.target.value);
                                    setUnsavedChangesNotif(true);
                                }}
                                placeholder="Type your email template here..."
                                className="mt-2 p-2 border rounded w-full text-gray-500 text-sm"
                                style={{ height: '180px' }}
                            />

                            <div className='flex items-center w-full justify-end mt-3'>
                                <button type="submit" className="px-8 p-2 hover:bg-transparent border text-white border-black rounded-full bg-black hover:text-black transition-colors duration-500 ease-in-out">
                                    Save
                                </button>
                            </div>
                        </div>

                        <div className=''>
                            <h1 className='font-medium text-lg flex items-center'>
                                How it works<InfoTooltip text={"You can include these variables in your template to\npersonalize each email with the recipient's information."} />
                            </h1>
                            <p className='mt-2 text-sm text-gray-500'>Use variables to customize your email for the person receiving it. Click on a variable to copy it and then paste it into your email.</p>
                            <div className="flex flex-wrap gap-2 mt-6" data-tour="onboard-setemail-2">
                                {variables.map((variable, index) => (
                                    <button
                                        key={index}
                                        className="flex items-center bg-gray-200 hover:bg-gray-300 text-black rounded px-4 py-2"
                                        onClick={() => copyToClipboard(variable.key)}
                                        type="button"
                                    >
                                        <FiCopy />
                                        <span className="ml-2 text-[12px]">{copyStatus[variable.key] ? 'Copied!' : variable.description}</span>
                                    </button>
                                ))}
                            </div>

                            <div class="mt-6">
                                <div class="grid grid-cols-2 gap-4 text-sm text-gray-500">
                                    <div class="col-span-2">
                                        <p class="font-semibold mb-1">Example: John Doe works in investment banking at Goldman Sachs</p>
                                    </div>
                                    <div class="-mb-2">
                                        <p class="mb-0">[their_first_name] = John</p>
                                    </div>
                                    <div class="mb-0">
                                        <p class="mb-0">[relevant_industry] = Investment Banking</p>
                                    </div>
                                    <div class="mb-0">
                                        <p class="mb-0">[their_company] = Goldman Sachs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <SendSettings userSubscription={userSubscription} />
                </div>
            </div>
        </div >
    );
}
