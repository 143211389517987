import React, { useState, useEffect } from 'react';
import Logo from '../../images/logo.svg'; // Ensure this path is correct
import { Link, NavLink } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { MdPlayCircleOutline } from "react-icons/md";
import { GrUpgrade } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { MdMailOutline } from 'react-icons/md';
import SignInSignOutButton from '../signInSignOut/signInSignOutButton'; // Ensure this path is correct
import { getUserData } from '../../components/awsAuth'; // Ensure this path is correct
import { useAuth0 } from "@auth0/auth0-react";

export default function DashboardMenu(props) {
    const { user, isAuthenticated } = useAuth0();
    const [numberOfNotifs, setNumberOfNotifs] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user?.email) {
                    const userInformation = await getUserData(user.email);
                    setNumberOfNotifs(userInformation.emails_to_send.filter(email => email.email_status === 'Unconfirmed').length);
                }
            } catch (error) {
                console.error("Error fetching user information:", error);
            }
        };

        fetchData();
    }, [user?.email]);

    const handleMouseEnter = () => setIsExpanded(true);
    const handleMouseLeave = () => setIsExpanded(false);

    const handleDemoClick = () => {
        window.open("https://player.vimeo.com/video/986751339?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", "_blank");
    };
    
    const baseStyle = {
        transition: 'width 0.3s ease-in-out',
        whiteSpace: 'nowrap',
        height: '100vh',
        borderRight: '1px solid #ccc',
        backgroundColor: '#fff',
    };

    const menuItemStyle = {
        paddingLeft: isExpanded ? '15px' : '0px', // Consistent padding
        display: 'flex',
        alignItems: 'center',
        justifyContent: isExpanded ? 'flex-start' : 'center', // Adjust justifyContent to handle icon + text
        width: '100%',
    };

    const iconStyle = 'text-lg mx-auto'; // Icon style remains the same for simplicity
    const iconStyle2 = 'text-xl mx-auto';

    const tabs = [
        { to: '/dashboard', icon: <IoHomeOutline className={iconStyle} />, label: 'Dashboard' },
        { to: '/autoapply', icon: <MdMailOutline className={iconStyle} />, label: 'Apply' },
        { to: '/settings', icon: <CgProfile className={iconStyle} />, label: 'Settings' },
        { to: '/upgrade', icon: <GrUpgrade className={iconStyle} />, label: 'Upgrade' },
        { onClick: handleDemoClick, icon: <MdPlayCircleOutline className={iconStyle2} />, label: 'Watch Demo' }
    ];

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ ...baseStyle, width: isExpanded ? '180px' : '80px' }}
        >
            <div className="flex flex-col items-center text-black px-2 overflow-hidden">
                <Link to='/' className={`flex justify-center items-center px-1 py-5 border-b border-gray-300 mb-5 w-full`}>
                    <img className="h-[20px]" src={Logo} alt="Logo" />
                    {isExpanded && <h1 className="text-black text-[17px] ml-2">Superday</h1>}
                </Link>
                {tabs.map(({ to, onClick, icon, label }) => (
                    to ? (
                        <NavLink
                            key={to}
                            to={to}
                            className={({ isActive }) =>
                                isActive ? `bg-black rounded-xl text-white text-sm py-3 w-full` : `text-sm py-4 w-full hover:text-black`
                            }
                            style={menuItemStyle}
                        >
                            <span className="flex items-center">
                                {icon}
                                {isExpanded && <span className='ml-2'>{label}</span>}
                            </span>
                        </NavLink>
                    ) : (
                        <div
                            key={label}
                            onClick={onClick}
                            className={`flex items-center text-sm py-4 w-full hover:text-black cursor-pointer`}
                            style={menuItemStyle}
                        >
                            <span className="flex items-center">
                                {icon}
                                {isExpanded && <span className='ml-2'>{label}</span>}
                            </span>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}