import React from 'react';
import { Link } from 'react-router-dom';
import './BlogList.css';

const BlogList = () => {
    const posts = [
        { id: 'first-post', title: 'How to: Write the Perfect Cold Email', date: '2024-07-23', image: '/blogs/first-post.jpg' },
        { id: 'second-post', title: 'How to: Coffee Chat', date: '2024-07-25', image: '/blogs/second-post.jpg' },
        { id: 'third-post', title: 'How to: Dress for the Chat', date: '2024-07-30', image: '/blogs/third-post.jpg' }

    ];

    return (
        <div className="blog-list-container">
            <h1 className="blog-title">Blog</h1>
            <div className="blog-grid">
                {posts.map(post => (
                    <div key={post.id} className="blog-card">
                        <Link to={`/blog/${post.id}`}>
                            <img src={post.image} alt={post.title} className="blog-card-image" />
                            <h2 className="blog-card-title">{post.title}</h2>
                        </Link>
                        <p className="blog-card-date">{post.date}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogList;
