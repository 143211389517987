import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../autoapply/featuredJobs.css';
import OnSuccessPopup from '../../components/OnSuccessPopup';
import { FaLock } from 'react-icons/fa';

const CustomizeApplication = ({ userAutoApplyData, userSubscription, userEmail }) => {
    const [phone, setPhone] = useState(userAutoApplyData?.phone_number || '');
    const [dob, setDob] = useState(userAutoApplyData?.dob || '');
    const [location, setLocation] = useState(userAutoApplyData?.user_location || '');
    const [major1, setMajor1] = useState(userAutoApplyData?.major || '');
    const [applyInternships, setApplyInternships] = useState(userAutoApplyData?.apply_internships || false);
    const [applyFullTime, setApplyFullTime] = useState(userAutoApplyData?.apply_fulltime || false);
    const [requireSponsorship, setRequireSponsorship] = useState(userAutoApplyData?.requires_sponsorship || false);

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const { DynamoDBClient, UpdateItemCommand } = require("@aws-sdk/client-dynamodb");

    const awsCredentials = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    };

    const dynamoDBClient = new DynamoDBClient({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });

    const handleAutoApplySubmit = async (event) => {
        event.preventDefault();

        const autoApplyData = {
            phone_number: phone,
            dob: dob,
            user_location: location,
            major: major1,
            apply_internships: applyInternships,
            apply_fulltime: applyFullTime,
            requires_sponsorship: requireSponsorship
        };

        try {
            const params = {
                TableName: "auto_apply",
                Key: {
                    client_email: { S: userEmail },
                },
                UpdateExpression: "set phone_number = :p, dob = :d, user_location = :l, major = :m, apply_internships = :in, apply_fulltime = :ft, requires_sponsorship = :rs",
                ExpressionAttributeValues: {
                    ":p": { S: autoApplyData.phone_number || "" },
                    ":d": { S: autoApplyData.dob || "" },
                    ":l": { S: autoApplyData.user_location || "" },
                    ":m": { S: autoApplyData.major || "" },
                    ":in": { BOOL: autoApplyData.apply_internships },
                    ":ft": { BOOL: autoApplyData.apply_fulltime },
                    ":rs": { BOOL: autoApplyData.requires_sponsorship}
                },
                ReturnValues: "UPDATED_NEW",
            };
    
            const command = new UpdateItemCommand(params);
            await dynamoDBClient.send(command);
        
            setUnsavedChanges(false);
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            console.error('Error updating auto apply data:', error);
        }
    };

    return (
        <div className="featured-jobs-wrapper">

            {showSuccessMessage && (
                <OnSuccessPopup showSuccessMessage={showSuccessMessage} successMessage={'Your profile has been updated.'} />
            )}

            <h2 className="featured-jobs-title">Customize Application</h2>

            {unsavedChanges && (<p className="ml-6 mb-2 -mt-6 text-red-500">You have unsaved changes*</p>)}

            <div className={`relative ${userSubscription !== "2" ? 'border border-[#e5e7eb] rounded-[20px]' : ''}`}>
                <div className={`featured-jobs-container ${userSubscription !== "2" ? 'pointer-events-none opacity-50 blur' : ''}`}>
                    <div className="form-group mb-2">
                        <label className="block text-sm font-medium text-gray-700">Phone:</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => { setPhone(e.target.value); setUnsavedChanges(true); }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            disabled={userSubscription !== "2"}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Date of Birth:</label>
                        <input
                            type="date"
                            value={dob}
                            onChange={(e) => { setDob(e.target.value); setUnsavedChanges(true); }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            disabled={userSubscription !== "2"}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Location:</label>
                        <input
                            type="text"
                            value={location}
                            onChange={(e) => { setLocation(e.target.value); setUnsavedChanges(true); }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            disabled={userSubscription !== "2"}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Major:</label>
                        <input
                            type="text"
                            value={major1}
                            onChange={(e) => { setMajor1(e.target.value); setUnsavedChanges(true); }}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            disabled={userSubscription !== "2"}
                        />
                    </div>
                    <div className="form-group mb-5">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <input
                                type="checkbox"
                                checked={applyInternships}
                                onChange={(e) => { setApplyInternships(e.target.checked); setUnsavedChanges(true); }}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                disabled={userSubscription !== "2"}
                            />
                            <span className="ml-2">Apply for Internships</span>
                        </label>
                    </div>
                    <div className="form-group mb-5">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <input
                                type="checkbox"
                                checked={applyFullTime}
                                onChange={(e) => { setApplyFullTime(e.target.checked); setUnsavedChanges(true); }}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                disabled={userSubscription !== "2"}
                            />
                            <span className="ml-2">Apply for Full-Time Roles</span>
                        </label>
                    </div>
                    <div className="form-group mb-5">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <input
                                type="checkbox"
                                checked={requireSponsorship}
                                onChange={(e) => { setRequireSponsorship(e.target.checked); setUnsavedChanges(true); }}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                disabled={userSubscription !== "2"}
                            />
                            <span className="ml-2">Require Sponsorship for Employment Visa Status</span>
                        </label>
                    </div>
                    <button 
                        onClick={handleAutoApplySubmit} 
                        className="submit-button w-full bg-black border text-white font-semibold py-2 px-4 rounded-md hover:bg-white hover:text-black hover:border-black transition duration-300 ease-in-out"
                        disabled={userSubscription !== "2"}
                    >
                        Save
                    </button>
                    <Link to='/settings/autoApply' className="text-center submit-button w-full bg-black border text-white font-semibold py-2 px-4 mt-4 rounded-md hover:bg-white hover:text-black hover:border-black transition duration-300 ease-in-out">
                        Add Experience
                    </Link>
                </div>

                {userSubscription !== "2" && (
                    <div className="absolute inset-0 flex justify-center items-center rounded-[20px] p-4 mb-20">
                        <div className="absolute inset-0 rounded-[20px]"></div>  {/* Blurred background */}
                        <div className="relative flex flex-col justify-center items-center">  {/* Content on top of the blurred background */}
                            <div className="bg-white p-3 mb-8 rounded-full flex justify-center items-center border border-gray-400">
                                <FaLock className="text-4xl" style={{ color: '#777' }} />
                            </div>
                            <Link to="/upgrade" className="text-center text-lg text-gray-700">
                                Upgrade to 
                                <span className="text-glow font-medium"> Premium </span> 
                                to unlock Auto Apply and get the most out of Superday.
                            </Link>
                        </div>
                    </div>
                )}

                <style jsx>{`
                    .text-glow {
                        text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
                        background: linear-gradient(to right, #603E8A, #3DC2EC);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                `}</style>
                
            </div>
        </div>
    );
};

export default CustomizeApplication;
