import React from 'react';
import StripeUpgrade from '../StripeUpgrade';

const DashboardProfile = ({ userData }) => {
    return (
        <div className={`border-gray-300 border-b px-12 flex items-center justify-between top-0 z-[99] ${userData.subscription !== '0' ? 'py-4' : ''}`}>
            <div className="flex items-center space-x-8">
                {userData.subscription === '2' ? (
                    <div className="flex items-center space-x-8">
                        <h1 className="text-lg font-semibold">
                            Welcome back, {`${userData.first_name}`}
                        </h1>
                        <p className="text-lg font-medium">
                            <span className="font-semibold">Subscription:</span> <span className='font-light'>Premium</span>
                        </p>
                    </div>
                ) : userData.subscription === '1' ? (
                    <div className="flex items-center space-x-8">
                        <h1 className="text-lg font-semibold">
                            Welcome back, {`${userData.first_name}`}
                        </h1>
                        <p className="text-lg font-medium">
                            <span className="font-semibold">Subscription:</span> <span className='font-light'>Pro</span>
                        </p>
                    </div>
                ) : (
                    <h1 className="flex items-center">
                        <div className="flex items-center space-x-6">
                            <h1 className="text-lg font-semibold">
                                Welcome back, {`${userData.first_name}`}
                            </h1>
                            <p className="text-lg font-medium">
                                <span className="font-semibold">Subscription:</span> <span className='font-light'>Free</span>
                            </p>
                        </div>
                    </h1>
                )}
            </div>

            {userData.subscription === '0' && (
                <div className="sticky right-0 mr-5 text-sm py-3 ml-auto">
                    <StripeUpgrade
                        priceID="price_1P08GUGgKK69nhANTPoA4hQ2"
                        copywriting="Upgrade Now"
                        styling="black"
                    />
                </div>
            )}
        </div>
    );
};

export default DashboardProfile;
