// src/components/faq/faqData.js
const faqs = [
    { question: "How do I know these emails are valid?", answer: "We use in-house validation software and partner with third-party email verification services to ensure the overwhelming majority of our emails are validated." },
    { question: "How many emails can I send?", answer: "The number of emails you can send depends on your subscription plan. Free members receive 20 emails per month on a weekly basis, while Pro and Premium members receive up to 200 emails per month. Please refer to our pricing page for more details." },
    { question: "How do I personalize emails?", answer: "You can personalize emails by using the custom fields in our email template. This allows you to address recipients by their name, mention their company, and more." },
    { question: "Who am I reaching out to?", answer: "When you select your industry, we filter through our database to find the best matches for professionals to reach out to." },
    { question: "What are the different ways I can send emails?", answer: "You can send emails directly through our platform immediately or schedule them to be sent automatically at a later date or time on a weekly basis." },
    { question: "Are other users emailing the same professionals as me every week?", answer: "Once we've identified matching professionals for you, our systems are set up to randomize and sample from all the potential matches. While possible, it's highly unlikely you receive any overlapping contacts as another user in the same week." },
];

export default faqs;
