import React, { useState } from 'react';
import './faq.css';
import faqs from './faqData'; // Import FAQ data

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container mb-24">
            <h2 className="faq-title text-center mb-8 my-6 font-medium text-[40px] lg:text-[50px] py-[35px] tracking-tight leading-tight md:leading-snug lg:leading-[115%]">Frequently Asked Questions</h2>
            <div className="faq-content max-w-4xl mx-auto">
                {faqs.map((faq, index) => (
                    <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
                        <div className="faq-question text-[16px] lg:text-[18px] cursor-pointer" onClick={() => handleToggle(index)}>
                            <h3>{faq.question}</h3>
                            <span className={`arrow ${activeIndex === index ? 'up' : 'down'}`}></span>
                        </div>
                        <div className="faq-answer">
                            <p>{faq.answer}</p>
                        </div>
                        <hr className="faq-divider" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Faq;
