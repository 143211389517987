export default function SelectionSection(props) {
    const containerClasses = `py-5 h-[300px] w-[300px] mb-4 border-gray-500 rounded-md mx-8 flex flex-col items-center justify-center border px-[28px]`;
    const imgClasses = `h-16 mb-3`;

    return (
        <div className={containerClasses}>
            <img className={imgClasses} src={props.image} />
            <h1 className="leading-[130%] font-medium text-xl mb-2">{props.title}</h1>
            <p className="my-5 text-md opacity-70">{props.content}</p>
        </div>
    );
}
