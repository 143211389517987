import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FirmCarousel from "../../components/firmCarousel/firmCarousel";
import HowItWorks from '../../components/howItWorks/howItWorks';
import { useAuth0 } from "@auth0/auth0-react";
import SignInSignOutButton from '../../components/signInSignOut/signInSignOutButton';
import WorkWithUs from '../../components/workWithUs/workWithUs';
import Faq from '../../components/faq/faq';
import FaqMobile from '../../components/faq/faqmobile'; 
//import Testimonials from '../../components/testimonials/testimonials';

export default function Home() {
    const { isAuthenticated } = useAuth0();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const useFlippingTexts = (wordsArray, flipDelay = 1000, displayDelay = 2000) => {
        const [currentWordIndices, setCurrentWordIndices] = useState(wordsArray.map(() => 0));
        const [isFlipping, setIsFlipping] = useState(true);

        useEffect(() => {
            const updateText = () => {
                if (isFlipping) {
                    setCurrentWordIndices(currentWordIndices.map((index, i) => (index + 1) % wordsArray[i].length));
                    setIsFlipping(false);
                } else {
                    setIsFlipping(true);
                }
            };

            const flipTimeout = setTimeout(updateText, isFlipping ? flipDelay : displayDelay);

            return () => clearTimeout(flipTimeout);
        }, [currentWordIndices, wordsArray, flipDelay, displayDelay, isFlipping]);

        return currentWordIndices.map((index, i) => ({
            text: wordsArray[i][index],
            isFlipping,
        }));
    };

    const [nameState, companyState, industryState] = useFlippingTexts(
        [["[their_first_name]", "Julia", "Sean"], ["[their_company]", "JP Morgan", "Amazon"], ["[their_industry]", "investment banking", "software engineering"]],
        350,
        2000
    );

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        const styleTag = document.createElement('style');
        styleTag.textContent = `
            @keyframes flip {
                0% { transform: rotateX(0); }
                50% { transform: rotateX(90deg); }
                100% { transform: rotateX(180deg); }
            }
            .flip-text {
                display: inline-block;
                backface-visibility: hidden;
                perspective: 1000px;
                transition: transform 1s;
            }
            .flip-text.flipping {
                transform: rotateX(180deg);
            }
            .email-container {
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 20px;
                background-color: #ffffff;
                width: 100%;
                max-width: 530px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                margin-top: 25px;
                min-height: 300px; /* Adjust this value to make the box taller */
                margin-right: 16px;
                margin-left: auto;
            }
            .email-header {
                margin-bottom: 14px;
                border-bottom: 1px solid #ccc;
                padding-bottom: 8px;
                font-size: 14px;
            }
            .email-header div {
                margin-bottom: 8px;
            }
            .email-recipient {
                font-weight: normal;
                color: #333;
            }
            .email-body {
                padding-top: 8px;
                font-size: 15px;
            }
            .email-text {
                text-align: left;
            }

            .text-glow {
                text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
                background: linear-gradient(to right, #603E8A, #3DC2EC);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

              .btn-gradient {
                background-image: linear-gradient(to right, rgb(1 134 218), rgb(182 49 167));
                border: 0;
              }
        `;
        document.head.appendChild(styleTag);

        return () => {
            if (document.head.contains(styleTag)) {
                document.head.removeChild(styleTag);
            }
        };
    }, [location]);

    return (
        <div className='pt-[50px] max-sm:pt-6 flex justify-center items-center flex-col mt-16'>
            <div className='my-2 flex flex-col-reverse lg:flex-row justify-center items-start max-w-[1400px]'>
                <div className='w-full lg:w-2/5 text-left mt-4 lg:mt-0 px-8 lg:px-0'>

                    <h1 className='max-sm:my-3 my-5 font-medium text-[30px] md:text-[44px] lg:text-[48px] tracking-tight leading-tight md:leading-snug lg:leading-[115%]'>
                    <span className='text-glow'>Connect</span> with top professionals <br /> in <span className='text-glow'>one click</span>.
                    </h1>
                
                    <p className='text-[14px] max-sm:mb-6 mb-10 lg:text-[18px] opacity-70'>We help you craft and send personalized emails to industry professionals to help you build your network and land your dream job.</p>
                    {isAuthenticated ? (
                        <Link to='/dashboard' className='border-black border bg-black text-white max-sm:px-8 max-sm:py-2 max-sm:text-sm px-16 py-3 font-medium text-md rounded-full hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out ml-16 sm:ml-0'>
                        View Dashboard
                    </Link>
                    
                    ) : (
                        <SignInSignOutButton title='Sign Up' buttonWidth={2}/>
                    )}
                </div>

                <div className='max-sm:justify-start max-sm:pl-8 w-full lg:w-2/5 flex justify-center items-start px-4 lg:px-0'>
                    <div className='email-container'>
                        <div className='email-header'>
                            <div><b>To:</b> <span className='email-recipient'>[recipient@example.com]</span></div>
                            <div><b>Subject:</b> Networking Opportunity</div>
                        </div>
                        <div className='email-body'>
                            <p className='email-text'>
                                Hi <span className={`flip-text text-glow ${nameState.isFlipping ? 'flipping' : ''}`} style={{ fontWeight: 'bold', display: 'inline-block', minWidth: '150px' }}>{nameState.text}<span style={{ color: 'black' }}>,</span></span><br />
                                I hope all is well! I saw you work at <span className={`flip-text text-glow ${companyState.isFlipping ? 'flipping' : ''}`} style={{ fontWeight: 'bold', display: 'inline-block', minWidth: '150px' }}>{companyState.text}</span><span style={{ color: 'black' }}> </span> <br/> and wanted to learn more about how you broke into <br/><span className={`flip-text text-glow ${industryState.isFlipping ? 'flipping' : ''}`} style={{ fontWeight: 'bold', display: 'inline-block', minWidth: '10px' }}>{industryState.text}</span><span style={{ color: 'black' }}>.<br/>Let me know when you have a chance to talk, thanks!</span><br />
                                <br/>Best,<br />
                                Superday
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <FirmCarousel />
            <HowItWorks />
            {isMobile ? <FaqMobile /> : <Faq />}
            {/* <Testimonials /> */}
            <WorkWithUs />
        </div>
    );
}   
