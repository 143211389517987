import WhiteLogo from '../images/logoWhite.svg';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="bg-black flex flex-col items-center p-8 mt-auto w-full">
            <div className="flex flex-col items-center justify-center text-center">
                <div className="flex items-center justify-center mb-5">
                    <img className="h-5" src={WhiteLogo} alt="Logo" />
                    <h1 className="text-white text-xl ml-3">Superday</h1>
                </div>
                <div className="flex flex-row justify-center gap-5">
                    <Link to='/tos'><p className='text-gray-300 text-sm focus:outline-none'>Terms of Service</p></Link>
                    <Link to='/privacy'><p className='text-gray-300 text-sm focus:outline-none'>Privacy Policy</p></Link>
                    <Link to='/contact'><p className='text-gray-300 text-sm focus:outline-none'>Contact Us</p></Link>
                </div>
            </div>
        </div>
    );
}
