import React, { useState } from 'react';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";
import { useNavigate } from 'react-router-dom';
import { getUserData, updateUserAttribute, addToUserAttribute } from '../../components/awsAuth';
import { useAuth0 } from "@auth0/auth0-react";
import cookie from 'cookiejs';

const awsCredentials = { accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, region: process.env.REACT_APP_AWS_REGION, };

const RefreshSendEmails = ({ userEmail, fullName, numToSend, emailsToUpdate, selectedEmailType, allEmails, onEmailsSent }) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const redirectUri = window.location.origin;
    const { user, isAuthenticated } = useAuth0();
    
    const dynamoDbClient = new DynamoDBClient({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });

    const docClient = DynamoDBDocumentClient.from(dynamoDbClient);

    const onClick = async () => { // Using async here
        if (loading) return;
        setLoading(true);
    
        const apiGatewayEndpointEmail = "https://ylqm1lgeud.execute-api.us-east-1.amazonaws.com/dev/send-emails";
        let statusCode = 0;
    
        try {
            // Await the update of emails before sending
            await updateEmails(emailsToUpdate, selectedEmailType, allEmails);
    
            const emailTypes = ['coffee'];
    
            for (const type of emailTypes) {
                const requestBody = {
                    email_type: type,
                    full_name: fullName,
                    client_email: userEmail,
                    use_mutex: true
                };

                const emailResponse = await fetch(apiGatewayEndpointEmail, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestBody)
                });


                if (!emailResponse.ok) throw new Error(`Sending ${type} emails failed`);
                const emailData = await emailResponse.json();
                console.log(`${type} emails sent successfully:`, emailData);
                statusCode = emailData['statusCode'];
            }

        } catch (error) {
            console.error('An error occurred:', error);
        } finally {

            setLoading(false);
            onEmailsSent(statusCode);
        }
    };    

    const onFailure = (response) => {
        console.error("Sending Failed:", response);
        setLoading(false);
    };

    const updateEmails = async (emailsToUpdate, selectedEmailType, allEmails) => {
        let userEmail = user.email;
        let numConfirmedEmails = 0;

        const selectedEmailsSet = new Set(emailsToUpdate.map(email => email.company_email));

        // Update selected emails to Confirmed
        let updatedEmails = allEmails.map(email => {
            if (email.email_status === 'Sent' || email.email_status === 'Pending Verification') {
                // Leave "Sent" emails unchanged
                return email;
            } else if (selectedEmailsSet.has(email.company_email) && email.email_status === 'Unconfirmed') {
                numConfirmedEmails++;
                return {
                    ...email,
                    email_status: 'Confirmed',
                    email_type: selectedEmailType,
                    day_sent: new Date().toISOString()
                };
            } else if (!selectedEmailsSet.has(email.company_email)) {
                return {
                    ...email,
                    email_status: 'Unconfirmed'
                };
            }
            return email;
        });

        await updateUserAttribute(userEmail, 'emails_to_send', updatedEmails);

        // Update number of email sends attempted
        await addToUserAttribute(userEmail, 'email_sends_attempted', 1);

    };

    return (
        <div>
            {loading ? (
                <p className='text-white px-8 py-2 font-medium text-lg bg-red-500 rounded-full'>Sending... Please do not refresh.</p>
            ) : (
                <button
                    className='w-full border-black border bg-black px-8 py-2 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out'
                    onClick={onClick}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                    redirectUri={redirectUri}
                    render={renderProps => (
                        <button className='border-black border bg-black px-8 py-2 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out' onClick={renderProps.onClick} disabled={renderProps.disabled}>
                            Send Selected ({numToSend})
                        </button>
                    )}
                >Send Selected ({numToSend})</button>
            )}
        </div>
    );
};

export default RefreshSendEmails;