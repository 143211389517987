const WorkWithUs = () => {
    return (
        <div className="w-full py-[85px] bg-[#121212] border-b-2 border-white border-opacity-70">
            <div className="max-w-lg mb-4 max-sm:px-8 px-4 text-center lg:max-w-2xl mx-auto">
                <h1 className='my-6 font-medium text-[40px] lg:text-[50px] tracking-tight leading-tight md:leading-snug lg:leading-[115%] text-white'>We're hiring at Superday</h1>
                <p className='text-[16px] lg:text-[18px] opacity-70 text-white'>Superday is expanding across growth and marketing. Use the link below to apply for the position.</p>
            </div>
            <div className="w-full flex items-center justify-center">
                <a href="https://1zatp7jxfnj.typeform.com/to/PK6v4hgx" target="_blank" rel="noopener noreferrer" className="inline-block text-sm border px-16 py-3 border-white rounded-full bg-white text-black font-medium mt-5 hover:bg-transparent hover:text-white transition-colors duration-500 ease-in-out">
                    Apply Now
                </a>
            </div>
        </div>
    )
}

export default WorkWithUs;
