import React, { useEffect, useState } from "react";
import { getUserData } from "../../components/awsAuth";
import { useAuth0 } from "@auth0/auth0-react";
import SignInSignOutButton from "../../components/signInSignOut/signInSignOutButton";
import LogoWhite from "../../images/logoWhite.svg";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  DynamoDBClient,
  ScanCommand,
  GetItemCommand,
} from "@aws-sdk/client-dynamodb";

const awsCredentials = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
};

const COLORS = {
  Free: "#808a83",
  Pro: "#0f5717",
  Premium: "#124078",
  null: "#E0E0E0", // Color for null industries
  "Software Engineering": "#17207a",
  "Product Management": "#56177a",
  Consulting: "#7a521a",
  "Venture Capital": "#a11715",
  "Private Equity": "#8A8CD9",
  "Investment Banking": "#0f4017",
};

export default function Admin(props) {
  const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
  const [isLoading, setIsLoading] = useState(true); // Consolidated loading state
  const [isAdmin, setIsAdmin] = useState(false);
  const [userCountData, setUserCountData] = useState([]);
  const [filteredUserCountData, setFilteredUserCountData] = useState([]);
  const [cumulativeUserCountData, setCumulativeUserCountData] = useState([]);
  const [emailSendsData, setEmailSendsData] = useState([]);
  const [cumulativeEmailSendsData, setCumulativeEmailSendsData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [statSetting, setStatSetting] = useState("userCount");
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [timeframe, setTimeframe] = useState("year");
  const [filteredEmailSendsData, setFilteredEmailSendsData] = useState([]);

  const dynamoDBClient = new DynamoDBClient({
    credentials: awsCredentials,
    region: awsCredentials.region,
  });

  useEffect(() => {
    if (isAuthenticated) {
      getUserData(user?.email)
        .then((data) => {
          setIsLoading(false); // Update loading state
          if (data.is_admin) {
            setIsAdmin(true);
            fetchAllData(); // Fetch data ONLY if the user is an admin
          }
        })
        .catch((err) => {
          return (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
              <div
                className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center"
                style={{ maxWidth: "500px" }}
              >
                Log in to view admin dashboard
                <br />
                <br />
                <SignInSignOutButton title="Sign in or create account" />
              </div>
            </div>
          );
        });
    } else {
      setIsLoading(authLoading); // Reflect auth loading state
    }
  }, [user?.email, isAuthenticated, authLoading]);

  const fetchAllData = async () => {
    let params = {
      TableName: "internify", // Replace with your actual table name
    };

    let allItems = [];
    let lastEvaluatedKey = null;

    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      try {
        const data = await dynamoDBClient.send(new ScanCommand(params));
        allItems = [...allItems, ...data.Items];
        lastEvaluatedKey = data.LastEvaluatedKey;

        console.log("Fetched batch from DynamoDB:", data.Items);
      } catch (err) {
        console.error(
          "Unable to fetch data. Error JSON:",
          JSON.stringify(err, null, 2)
        );
        break;
      }
    } while (lastEvaluatedKey);

    console.log("All items fetched from DynamoDB:", allItems);
    processData(allItems);
  };

  const processData = (items) => {
    try {
      // Get all dates between the first and last date in the data
      const getAllDates = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);
        const lastDate = new Date(endDate);

        while (currentDate <= lastDate) {
          dates.push(new Date(currentDate).toISOString().split('T')[0]);
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
      };

      const userSignupDates = items
        .filter((item) => item.signup_timestamp && item.signup_timestamp.S)
        .map((item) => item.signup_timestamp.S.split(" ")[0]); // Extract the date part

      const firstDate = new Date(Math.min(...userSignupDates.map(date => new Date(date))));
      const lastDate = new Date(Math.max(...userSignupDates.map(date => new Date(date))));
      const allDates = getAllDates(firstDate, lastDate);

      const dateCounts = userSignupDates.reduce((acc, date) => {
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      // Include zero counts for missing dates
      const formattedUserCountData = allDates.map((date) => ({
        date,
        userCount: dateCounts[date] || 0, // Set to 0 if date is missing in dateCounts
      }));

      formattedUserCountData.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      ); // Sort by date

      setUserCountData(formattedUserCountData);
      setFilteredUserCountData(formattedUserCountData); // Set initial filtered data

      // Calculate cumulative user count
      let cumulativeCount = 0;
      const cumulativeData = formattedUserCountData.map((item) => {
        cumulativeCount += item.userCount;
        return { date: item.date, userCount: cumulativeCount };
      });

      setCumulativeUserCountData(cumulativeData);

      // Process email sends data in a similar way
      const emailSends = items
        .filter((item) => item.emails_to_send && item.emails_to_send.L)
        .flatMap((item) =>
          item.emails_to_send.L.filter(
            (email) => email.M.email_status.S === "Sent"
          ).map((email) => ({
            date: email.M.day_sent.S.split("T")[0], // Extract the date part
            status: email.M.email_status.S,
          }))
        );

      const emailSendsCounts = emailSends.reduce((acc, item) => {
        acc[item.date] = (acc[item.date] || 0) + 1;
        return acc;
      }, {});

      const formattedEmailSendsData = allDates.map((date) => ({
        date,
        emailSendsAttempted: emailSendsCounts[date] || 0, // Set to 0 if date is missing in emailSendsCounts
      }));

      formattedEmailSendsData.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      ); // Sort by date

      setEmailSendsData(formattedEmailSendsData);

      // Calculate cumulative email sends count
      let cumulativeEmailCount = 0;
      const cumulativeEmailData = formattedEmailSendsData.map((item) => {
        cumulativeEmailCount += item.emailSendsAttempted;
        return { date: item.date, emailSendsAttempted: cumulativeEmailCount };
      });

      setCumulativeEmailSendsData(cumulativeEmailData);

      // Process subscription data
      const subscriptions = items.map((item) => {
        let subscription =
          item.subscription && item.subscription.S ? item.subscription.S : "0";
        if (subscription === "0" || subscription === null)
          subscription = "Free";
        if (subscription === "1") subscription = "Pro";
        if (subscription === "2") subscription = "Premium";
        return subscription;
      });

      const subscriptionCounts = subscriptions.reduce((acc, subscription) => {
        acc[subscription] = (acc[subscription] || 0) + 1;
        return acc;
      }, {});
      console.log("Subscription counts:", subscriptionCounts);

      const formattedSubscriptionData = Object.keys(subscriptionCounts).map(
        (subscription) => ({
          name: subscription,
          value: subscriptionCounts[subscription],
        })
      );
      console.log(
        "Formatted subscription data for chart:",
        formattedSubscriptionData
      );

      setSubscriptionData(formattedSubscriptionData);

      // Process industry data
      const industries = items.map((item) =>
        item.industry && item.industry.L
          ? item.industry.L.map((ind) => ind.S)
          : ["null"]
      );
      const industryCounts = industries.flat().reduce((acc, industry) => {
        acc[industry] = (acc[industry] || 0) + 1;
        return acc;
      }, {});
      console.log("Industry counts:", industryCounts);

      const formattedIndustryData = Object.keys(industryCounts).map(
        (industry) => ({
          name: industry,
          value: industryCounts[industry],
        })
      );
      console.log("Formatted industry data for chart:", formattedIndustryData);

      setIndustryData(formattedIndustryData);
    } catch (err) {
      console.error("Error processing data:", err);
    }
  };

  const handleSearch = async () => {
    if (!searchInput) {
      setSearchError("Please enter a client email to search.");
      return;
    }

    const params = {
      TableName: "internify", // Replace with your actual table name
      Key: {
        client_email: { S: searchInput },
      },
    };

    try {
      const data = await dynamoDBClient.send(new GetItemCommand(params));
      if (data.Item) {
        const memberSince = data.Item.signup_timestamp
          ? data.Item.signup_timestamp.S.split(" ")[0]
          : "N/A";
        const emailsToSend =
          data.Item.emails_to_send && data.Item.emails_to_send.L
            ? data.Item.emails_to_send.L
            : [];
        const totalEmailsSent = emailsToSend.filter(
          (email) => email.M.email_status.S === "Sent"
        ).length;

        let subscription =
          data.Item.subscription && data.Item.subscription.S
            ? data.Item.subscription.S
            : "0";
        if (subscription === "0" || subscription === null)
          subscription = "Free";
        if (subscription === "1") subscription = "Pro";
        if (subscription === "2") subscription = "Premium";

        const industries =
          data.Item.industry && data.Item.industry.L
            ? data.Item.industry.L.map((ind) => ind.S)
            : ["null"];

        setSearchResult({
          email: searchInput,
          memberSince,
          totalEmailsSent,
          subscription,
          industries,
        });
        setSearchError("");
      } else {
        setSearchResult(null);
        setSearchError("User not found.");
      }
    } catch (err) {
      console.error("Error searching for user:", err);
      setSearchError("Error searching for user.");
    }
  };

  const filterUserCountData = (timeframe) => {
    const now = new Date();
    let filteredData;

    if (timeframe === "week") {
      filteredData = userCountData.filter((data) => {
        const dataDate = new Date(data.date);
        return now - dataDate <= 7 * 24 * 60 * 60 * 1000; // Last 7 days
      });
    } else if (timeframe === "month") {
      filteredData = userCountData.filter((data) => {
        const dataDate = new Date(data.date);
        return (
          dataDate.getMonth() === now.getMonth() &&
          dataDate.getFullYear() === now.getFullYear()
        );
      });
    } else if (timeframe === "year") {
      filteredData = userCountData.filter((data) => {
        const dataDate = new Date(data.date);
        return dataDate.getFullYear() === now.getFullYear(); // Current year
      });
    }

    setFilteredUserCountData(filteredData);
    setTimeframe(timeframe);
  };

  useEffect(() => {
    filterUserCountData(timeframe); // Filter data based on the selected timeframe
  }, [userCountData, timeframe]);

  const filterEmailSendsData = (timeframe) => {
    const now = new Date();
    let filteredData;

    if (timeframe === "week") {
      filteredData = emailSendsData.filter((data) => {
        const dataDate = new Date(data.date);
        return now - dataDate <= 7 * 24 * 60 * 60 * 1000; // Last 7 days
      });
    } else if (timeframe === "month") {
      filteredData = emailSendsData.filter((data) => {
        const dataDate = new Date(data.date);
        return (
          dataDate.getMonth() === now.getMonth() &&
          dataDate.getFullYear() === now.getFullYear()
        );
      });
    } else if (timeframe === "year") {
      filteredData = emailSendsData.filter((data) => {
        const dataDate = new Date(data.date);
        return dataDate.getFullYear() === now.getFullYear(); // Current year
      });
    }

    setFilteredEmailSendsData(filteredData);
    setTimeframe(timeframe);
  };

  useEffect(() => {
    filterEmailSendsData(timeframe); // Filter data based on the selected timeframe
  }, [emailSendsData, timeframe]);

  if (!isAuthenticated) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
        <div
          className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center text-center"
          style={{ maxWidth: "700px" }}
        >
          <h1 className="text-3xl mb-4">Login Error</h1>
          You're currently not signed in or do not have an account.
          <br></br>
          <br></br>
          <SignInSignOutButton title="Sign in or create account"></SignInSignOutButton>
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
        <div
          className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center text-center"
          style={{ maxWidth: "700px" }}
        >
          <h1 className="text-3xl mb-4">Error</h1>
          Sorry, you do not have permission to view this page.
          <br></br>
          <br></br>
          <Link
            to="/dashboard"
            className="border-black border bg-black text-white max-sm:px-8 max-sm:py-2 max-sm:text-sm px-16 py-3 font-medium text-md rounded-full hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out ml-16 sm:ml-0"
          >
            Back to Dashboard
          </Link>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-white">
        <img src={LogoWhite} alt="Superday Logo"></img>
      </div>
    );
  }

  return (
    <>
      <div className="flex overflow-x-hidden sm:min-w-[1200px]">
        <div className="w-full p-12 h-[100vh] overflow-y-scroll">
          <h1 className="mt-10 text-4xl font-medium self-start">
            Admin Dashboard
          </h1>
          <div className="flex border-b mb-10 py-5 border-gray-400 overflow-x-scroll sm:overflow-x-auto text-center">
            <button
              onClick={() => setStatSetting("userCount")}
              className={`max-[600px]:pt-4 px-8 p-2 rounded-md border text-sm ${
                statSetting === "userCount"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } hover:bg-black hover:text-white border-black`}
            >
              User Count
            </button>
            <button
              onClick={() => setStatSetting("emailsSent")}
              className={`max-[600px]:pt-4 ml-4 px-6 p-2 rounded-md border text-sm ${
                statSetting === "emailsSent"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } hover:bg-black hover:text-white border-black`}
            >
              Emails Sent
            </button>
            <button
              onClick={() => setStatSetting("subscribedUsers")}
              className={`ml-4 px-6 p-2 rounded-md border text-sm ${
                statSetting === "subscribedUsers"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } hover:bg-black hover:text-white border-black`}
            >
              Subscribed Users
            </button>
            <button
              onClick={() => setStatSetting("industries")}
              className={`max-[600px]:pt-4 ml-4 px-6 p-2 rounded-md border text-sm ${
                statSetting === "industries"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } hover:bg-black hover:text-white border-black`}
            >
              Industries
            </button>
            <button
              onClick={() => setStatSetting("searchUser")}
              className={`max-[600px]:pt-4 ml-4 px-6 p-2 rounded-md border text-sm ${
                statSetting === "searchUser"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } hover:bg-black hover:text-white border-black`}
            >
              Search User
            </button>
          </div>

          {statSetting === "userCount" && (
            <>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>User Count per Day</h2>
                <div className="flex justify-center gap-4 mt-4">
                  <button
                    onClick={() => filterUserCountData("week")}
                    className={`p-2 rounded-md border text-sm ${
                      timeframe === "week"
                        ? "bg-black text-white"
                        : "bg-transparent text-black"
                    } hover:bg-black hover:text-white border-black`}
                  >
                    1 Week
                  </button>
                  <button
                    onClick={() => filterUserCountData("month")}
                    className={`p-2 rounded-md border text-sm ${
                      timeframe === "month"
                        ? "bg-black text-white"
                        : "bg-transparent text-black"
                    } hover:bg-black hover:text-white border-black`}
                  >
                    1 Month
                  </button>
                  <button
                    onClick={() => filterUserCountData("year")}
                    className={`p-2 rounded-md border text-sm ${
                      timeframe === "year"
                        ? "bg-black text-white"
                        : "bg-transparent text-black"
                    } hover:bg-black hover:text-white border-black`}
                  >
                    1 Year
                  </button>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={filteredUserCountData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis
                    label={{
                      value: "New Users",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="userCount"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
              >
                <h2>Cumulative User Count</h2>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={cumulativeUserCountData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis
                    label={{
                      value: "Total Users",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="userCount"
                    stroke="#82ca9d"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </>
          )}

          {statSetting === "emailsSent" && (
            <>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>Emails Sent per Day</h2>
                <div className="flex justify-center gap-4 mt-4">
                  <button
                    onClick={() => filterEmailSendsData("week")}
                    className={`p-2 rounded-md border text-sm ${
                      timeframe === "week"
                        ? "bg-black text-white"
                        : "bg-transparent text-black"
                    } hover:bg-black hover:text-white border-black`}
                  >
                    1 Week
                  </button>
                  <button
                    onClick={() => filterEmailSendsData("month")}
                    className={`p-2 rounded-md border text-sm ${
                      timeframe === "month"
                        ? "bg-black text-white"
                        : "bg-transparent text-black"
                    } hover:bg-black hover:text-white border-black`}
                  >
                    1 Month
                  </button>
                  <button
                    onClick={() => filterEmailSendsData("year")}
                    className={`p-2 rounded-md border text-sm ${
                      timeframe === "year"
                        ? "bg-black text-white"
                        : "bg-transparent text-black"
                    } hover:bg-black hover:text-white border-black`}
                  >
                    1 Year
                  </button>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={filteredEmailSendsData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="emailSendsAttempted"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>

              <div
                style={{
                  textAlign: "center",
                  marginTop: "40px",
                  marginBottom: "20px",
                }}
              >
                <h2>Cumulative Emails Sent</h2>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={cumulativeEmailSendsData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="emailSendsAttempted"
                    stroke="#82ca9d"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </>
          )}

          {statSetting === "subscribedUsers" && (
            <>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>Subscription Distribution</h2>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={subscriptionData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ name, percent }) =>
                      `${name}: ${(percent * 100).toFixed(0)}%`
                    }
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {subscriptionData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </>
          )}

          {statSetting === "industries" && (
            <>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>Industry Distribution</h2>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={industryData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ name, percent }) =>
                      `${name}: ${(percent * 100).toFixed(0)}%`
                    }
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {industryData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[entry.name] || "#8884d8"}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </>
          )}

          {statSetting === "searchUser" && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <h2>Search User</h2>
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Enter client email"
                className="border p-2 rounded"
                style={{ width: "300px" }} // Increased the width to fit input
              />
              <button
                onClick={handleSearch}
                className="bg-black text-white p-2 rounded ml-2 border hover:bg-white hover:text-black hover:border-black"
              >
                Search
              </button>
              {searchError && (
                <div className="text-red-500 mt-2">{searchError}</div>
              )}
              {searchResult && (
                <div className="mt-4">
                  <p>
                    <strong>Email:</strong> {searchResult.email}
                  </p>
                  <p>
                    <strong>Member Since:</strong> {searchResult.memberSince}
                  </p>
                  <p>
                    <strong>Total Emails Sent:</strong>{" "}
                    {searchResult.totalEmailsSent}
                  </p>
                  <p>
                    <strong>Subscription:</strong> {searchResult.subscription}
                  </p>
                  <p>
                    <strong>Industries:</strong>{" "}
                    {searchResult.industries.join(", ")}
                  </p>
                  <p>
                    <strong>Add Credits:</strong>{" "}
                    <input
                      type="text"
                      placeholder="# Credits"
                      className="border p-2 rounded mr-2"
                      style={{ width: "100px" }}
                    />
                    <button className="bg-black text-white p-2 px-5 rounded border border-black hover:bg-white hover:text-black">
                      Add
                    </button>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
