import React, { useState } from 'react';
import { GoogleLogin } from '@leecheuk/react-google-login';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'cookiejs';

const CLIENT_ID = "271206359168-68u3c65gfra9h808s04ceorjp56iredj.apps.googleusercontent.com";

const GoogleOAuth = ({ userEmail }) => {
    let navigate = useNavigate();
    const [state] = useState(uuidv4());
    const [loading, setLoading] = useState(false);
    const redirectUri = window.location.origin;

    const onSuccess = (response) => {
        console.log("Login Successful:", response);
        setLoading(true);

        if (!response.code) {
            console.log("No authorization code found in the response.");
            setLoading(false);
            return;
        }

        const apiGatewayEndpointToken = "https://ylqm1lgeud.execute-api.us-east-1.amazonaws.com/dev/token-exchange";

        (async () => {
            try {

                const tokenResponse = await fetch(apiGatewayEndpointToken, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        code: response.code,
                        state: state,
                        redirect_uri: redirectUri,
                        email: userEmail // Make sure userEmail is correctly determined here
                    })
                });

                if (!tokenResponse.ok) throw new Error('Token exchange failed');
                const tokenData = await tokenResponse.json();
                console.log('Token Data:', tokenData);
                setLoading(false);
                window.location.reload();
            } catch (error) {
                console.error('An error occurred:', error);
                setLoading(false);
            }
        })();
    };

    const onFailure = (response) => {
        console.error("Login Failed:", response);
        setLoading(false);
    };

    
    return (
        <div>
            {loading ? (
                <p className='text-white px-8 py-2 font-medium text-lg bg-red-500 rounded-full'>Authorizing... Please do not refresh.</p>
            ) : (
                <GoogleLogin
                    clientId={CLIENT_ID}
                    buttonText="Login with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    scope="https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid"
                    isSignedIn={true}
                    responseType="code"
                    accessType="offline"
                    prompt="consent"
                    redirectUri={redirectUri}
                    state={state}
                />
            )}
        </div>
    );
};

export default GoogleOAuth;