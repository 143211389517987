import React from 'react';
import '../autoapply/autoApplyContent.css';

const AutoApplyContent = ({ userJobHistory }) => {
    return (
        <div className="overflow-y-scroll h-[571px] shadow" style={{ scrollbarWidth: "thin" }}>
            <div className="table-wrapper">
                <table className="min-w-full bg-white table-fixed">
                    <thead>
                        <tr>
                            <th className="sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Company
                            </th>
                            <th className="sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Role
                            </th>
                            <th className="sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Date Submitted
                            </th>
                            <th className="sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider z-50" style={{ width: '20%' }}>
                                Link
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(userJobHistory.entries()).map(([key, job], index) => (
                            <tr key={index}>
                                <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                    {job.company_name}
                                </td>
                                <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                    {job.role}
                                </td>
                                <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                    {job.date_applied}
                                </td>
                                <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                                <a href={job.link.startsWith('http://') || job.link.startsWith('https://') ? job.link : `https://${job.link}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-50">
                                    View
                                </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AutoApplyContent;
