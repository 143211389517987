import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const InfoTooltip = ({ text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleClick = () => {
        setShowTooltip(!showTooltip);
    };

    const handleDocumentClick = (e) => {
        if (!e.target.closest('.tooltip-container')) {
            setShowTooltip(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const tooltipStyles = {
        container: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '0.5rem',  // Add margin to align properly
        },
        tooltip: {
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '1.5rem',
            zIndex: 10,
            maxWidth: '600px',
        },
        tooltipText: {
            padding: '0.5rem',
            fontSize: '0.8rem',
            lineHeight: '1.2rem',
            color: 'white',
            backgroundColor: 'black',
            whiteSpace: 'pre',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '0.25rem',
            maxWidth: '600px',
        },
        arrow: {
            width: '0.75rem',
            height: '0.75rem',
            marginTop: '-0.25rem',
            transform: 'rotate(45deg)',
            backgroundColor: 'black',
        },
        icon: {
            fontSize: '1rem',  // Adjust this value to make the icon smaller or larger
            color: 'gray',
            cursor: 'pointer',
        },
    };

    return (
        <div
            className="tooltip-container"
            style={tooltipStyles.container}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <AiOutlineInfoCircle style={tooltipStyles.icon} />
            {showTooltip && (
                <div style={tooltipStyles.tooltip}>
                    <span style={tooltipStyles.tooltipText}>{text}</span>
                    <div style={tooltipStyles.arrow}></div>
                </div>
            )}
        </div>
    );
};

export default InfoTooltip;
