import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserData, getUserAutoApplyData, createAutoApplyProfile, getUserJobHistory } from '../../components/awsAuth';
import DashboardMenu from '../../components/dashboard/dashboardMenu';
import SignInSignOutButton from '../../components/signInSignOut/signInSignOutButton';
import Nav from '../../pages/nav/nav';
import LogoWhite from '../../images/logoWhite.svg';
import Logo from '../../images/logo.svg';
import AutoApplyView from './autoApplyView';
import CustomizeApplication from '../../pages/autoapply/featuredJobs';

export default function AutoApply(props) {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [userData, setUserData] = useState(null);
    const [userAutoApplyData, setUserAutoApplyData] = useState(null);
    const [userAutoApply, setUserAutoApply] = useState(null);
    const [userJobHistory, setUserJobHistory] = useState(new Map());
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authLoading && !isAuthenticated) {
            navigate("/");
        }
        if (isAuthenticated) {
            getUserData(user?.email)
                .then(data => {
                    setUserData(data);
                    setIsLoading(false);

                    if (!data?.industry) {
                        navigate('/onboard');
                        window.location.reload();
                    }

                    if (data.subscription === "2") {
                        getUserAutoApplyData(user?.email)
                            .then(autoApplyData => {
                                if (autoApplyData) {
                                    setUserAutoApplyData(autoApplyData);
                                    setUserAutoApply(autoApplyData.auto_apply || false);
                    
                                    // Fetch job details using the links in job_history
                                    const jobLinks = Object.values(autoApplyData.job_history);
                                    getUserJobHistory(jobLinks)
                                        .then(jobMap => {
                                            setUserJobHistory(jobMap);
                                        })
                                        .catch(err => {
                                            console.error('Error fetching job history:', err);
                                            setUserJobHistory(new Map()); // Set an empty map in case of an error
                                        });
                    
                                } else {
                                    createAutoApplyProfile(user?.email).then(() => {
                                        setUserAutoApply(false);
                                        setUserJobHistory(new Map());
                                    });
                                }
                            })
                            .catch(err => {
                                console.error('Error fetching auto apply data:', err);
                            });
                    } else {
                        setUserAutoApply(false);
                        setUserAutoApplyData({});
                    }                    

                })
                .catch(err => {
                    return (
                        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                            <div className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center" style={{ maxWidth: '500px' }}>
                                Log in to view dashboard
                                <br /><br />
                                <SignInSignOutButton title='Sign in or create account' />
                            </div>
                        </div>
                    );
                });
        } else {
            setIsLoading(authLoading);
        }
    }, [user?.email, isAuthenticated, authLoading, navigate]);

    if (window.innerWidth < 600) {
        return (
            <div>
                <Nav />
                <div className='flex justify-center items-center h-[90vh] flex-col px-16'>
                    <img src={Logo} alt='superday_logo' className='h-16 block' />
                    <br />
                    <h1 className='text-3xl font-medium'>Sorry about this</h1>
                    <p className='flex justify-center items-center text-center mt-3'>To access your dashboard, please visit this page on your computer.</p>
                </div>
            </div>
        );
    }

    if (isLoading || userAutoApply === null || userAutoApplyData == null) {  
        return (
            <div className="flex justify-center items-center h-screen bg-black text-white">
                <img src={LogoWhite} alt='superday_logo' />
            </div>
        );
    }

    return (
        <div className="flex min-w-[1200px] h-[100vh]">
            <DashboardMenu />
            <div className="flex flex-grow justify-center items-center space-x-10">
                <CustomizeApplication userEmail={userData.client_email} userAutoApplyData={userAutoApplyData} userSubscription={userData.subscription} />
                <AutoApplyView userEmail={userData.client_email} userSubscription={userData.subscription} userAutoApply={userAutoApply} userJobHistory={userJobHistory} />
            </div>
        </div>
    );
}