import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';

const stripePromise = loadStripe('pk_live_51P087RGgKK69nhANGsD0J1ZDRxTKrHpg9U4onINFfwADE0hhcpQO7DGA4eGYhyfcwQadoD7Lz2o1sd2yWrSDNXy5006u8vTy94');

const StripeUpgrade = ({ priceID, copywriting, styling }) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    const handleCheckout = async () => {
        if (!isAuthenticated) {
            loginWithRedirect({ redirectUri: `${window.location.origin}/dashboard` });
            return;
        }

        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: priceID, quantity: 1 }],
            mode: 'subscription',
            successUrl: 'https://joinsuperday.com/dashboard',
            cancelUrl: 'https://joinsuperday.com/upgrade',
        });

        if (error) {
            console.error('Stripe checkout error:', error.message);
        }
    };

    // Determine button class based on `styling` prop
    const buttonClass = styling === 'white'
        ? 'border border-black text-black bg-white rounded-full px-8 py-2 hover:bg-black hover:text-white hover:border-white duration-500 ease-in-out'
        : 'border border-black text-white bg-black rounded-full px-8 py-2 hover:bg-white hover:text-black duration-500 ease-in-out';

    return (
        <button
            onClick={handleCheckout}
            className={buttonClass}>
            {copywriting}
        </button>
    );
};

export default StripeUpgrade;
