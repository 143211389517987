import React, { useEffect, useState } from 'react';

export default function OnUpgradePopup({ showMessage, message }) {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (showMessage) {
      setShouldRender(true); // Ensure the component is rendered in the DOM
      const fadeInTimeout = setTimeout(() => {
        setIsVisible(true); // Start fading in after the component is rendered
      }, 10); // Small delay to ensure CSS transition applies correctly

      // After 2 seconds plus fade-out duration, start fading out
      const fadeOutTimeout = setTimeout(() => {
        setIsVisible(false);
      }, 2000 + 500); // Visible duration + fade-out duration

      // Remove component from DOM after fade-out completes
      const removeTimeout = setTimeout(() => {
        setShouldRender(false);
      }, 2000 + 1000); // Visible duration + fade-in + fade-out durations

      return () => {
        clearTimeout(fadeInTimeout);
        clearTimeout(fadeOutTimeout);
        clearTimeout(removeTimeout);
      };
    } else {
      // If `showSuccessMessage` is false, immediately start hiding the component
      setIsVisible(false);
      // Remove component from DOM after fade-out completes
      const timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 500); // Fade-out duration
      return () => clearTimeout(timeoutId);
    }
  }, [showMessage]);

  if (!shouldRender) {
    return null; // Don't render the component if it shouldn't be in the DOM
  }

  return (
    <div style={{
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      background: '#333',
      color: '#fff',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      minWidth: '300px',
      maxWidth: '90%',
      zIndex: 50,
      padding: '20px',
      opacity: isVisible ? 1 : 0,
      transition: 'opacity 0.5s ease-in-out',
    }}>
        <div className="flex items-center">
            <svg className="w-8 h-8 fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm1 17h-2v-2h2v2zm0-4h-2V6h2v7z"/>
            </svg>

            <div className='ml-2'>
            <p className="font-semibold">Notice!</p>
            <p className="text-sm">{message}</p>
            </div>
        </div>
    </div>
  );
}
