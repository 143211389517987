import React, { useEffect, useRef } from 'react';
import './firmCarousel.css';
import Amazon from './companies/Amazon.png';
import Chase from './companies/Chase.png';
import Netflix from './companies/Netflix.png';
import BoFA from './companies/BoFA.png';
import Citi from './companies/Citi.png';
import Deloitte from './companies/Deloitte.png';
import JPM from './companies/JPM.png';
import Meta from './companies/Meta.png';
import Microsoft from './companies/Microsoft.png';

const images = [Amazon, Chase, Netflix, BoFA, Citi, Deloitte, JPM, Meta, Microsoft];

const FirmCarousel = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        const carousel = carouselRef.current;
        let isPaused = false;
        let animationFrame;  // Declare animationFrame here

        const startAnimation = () => {
            if (carousel) {
                const totalWidth = carousel.scrollWidth / 2;
                const duration = 20; // Adjust duration for smoothness

                const animate = () => {
                    if (isPaused) return;

                    carousel.scrollLeft += 1;

                    if (carousel.scrollLeft >= totalWidth) {
                        carousel.scrollLeft = 0;
                    }

                    animationFrame = requestAnimationFrame(animate);
                };

                animate();

                carousel.addEventListener('mouseenter', () => {
                    isPaused = true;
                    cancelAnimationFrame(animationFrame);
                });

                carousel.addEventListener('mouseleave', () => {
                    isPaused = false;
                    animate();
                });
            }
        };

        startAnimation();

        return () => {
            if (carousel) {
                cancelAnimationFrame(animationFrame);  // Clean up animationFrame
            }
        };
    }, []);

    return (
        <div className="flex-col items-center justify-center mt-14 mb-10 xs:my-0">
            <div className="carousel-wrapper" ref={carouselRef}>
                <div className="carousel">
                    {images.concat(images).map((image, index) => (
                        <img key={index} src={image} alt={`Slide ${index}`} className="slide" />
                    ))}
                </div>
            </div>
            <p className='my-6'>Get <b>more offers</b> from the top companies</p>
        </div>
    );
};

export default FirmCarousel;
