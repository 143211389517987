// src/components/faq/faqmobile.js
import React, { useState } from 'react';
import './faqmobile.css';
import faqs from './faqData'; // Import FAQ data

const FaqMobile = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container-mobile py-10">
            <h2 className="faq-title-mobile text-center font-medium text-lg py-[25px]">Frequently Asked Questions</h2>
            <div className="faq-content-mobile max-w-4xl mx-auto">
                {faqs.map((faq, index) => (
                    <div key={index} className={`faq-item-mobile ${activeIndex === index ? 'active' : ''}`}>
                        <div className="faq-question-mobile" onClick={() => handleToggle(index)}>
                            <h3>{faq.question}</h3>
                            <span className={`arrow ${activeIndex === index ? 'up' : 'down'}`}></span>
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer-mobile">
                                <p>{faq.answer}</p>
                            </div>
                        )}
                        <hr className="faq-divider-mobile" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FaqMobile;
