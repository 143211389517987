import { useEffect } from 'react';
import steps from './tourSteps';
import { addInitialEmails } from '../components/awsAuth';
import { useAuth0 } from "@auth0/auth0-react";

// Common function to handle ending the tour
async function handleEndTour(setIsOpen, user) {
    if (typeof setIsOpen === 'function') {
        setIsOpen(false);  // Close the tour
        await addInitialEmails(user?.email);
    }
}

// Placeholder Close component
const Close = ({ setIsOpen }) => {
    const { user } = useAuth0();

    useEffect(() => {
        handleEndTour(setIsOpen, user);
    }, [setIsOpen, user]);

    return null;
};

// Placeholder Badge component
const Badge = () => null;

// Navigation component
const Navigation = ({ setCurrentStep, currentStep, setIsOpen }) => {
    const { user } = useAuth0();

    async function incrementStep() {
        if (currentStep === steps.length - 1) {
            // Handle the last step
            await handleEndTour(setIsOpen, user);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    return (
        <div className='w-full flex justify-center'>
            {(currentStep !== 4) && (
                <button 
                    type="button" 
                    className="w-[200px] py-2 text-white bg-black rounded-full mt-6 hover:bg-white hover:border-black hover:text-black hover:border duration-500 ease-in-out" 
                    onClick={incrementStep}
                >
                    {currentStep === 5 ? 'Finish!' : 'Continue'}
                </button>
            )}
        </div>
    );    
};

const tourUtils = { Close, Badge, Navigation };
export default tourUtils;