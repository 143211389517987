import * as React from 'react';
import { Link } from 'react-router-dom'; // Use react-router-dom's Link
import './nav.css';
import { useState, useEffect } from 'react';
import Logo from '../../images/logo.svg';
import SignInSignOutButton from '../../components/signInSignOut/signInSignOutButton.js';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserData } from '../../components/awsAuth.js';

export default function Nav() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [isAdmin, setIsAdmin] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
          getUserData(user?.email)
            .then((data) => {
              if (data.is_admin) {
                console.log("set as admin");
                setIsAdmin(true);
              }
            })
            .catch((err) => {
              return (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                  <div
                    className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center"
                    style={{ maxWidth: "500px" }}
                  >
                    An unexpected error has occurred. Please check your connect or log back into your account.
                  </div>
                </div>
              );
            });
        }
      }, [user?.email, isAuthenticated,]);


      const styleTag = document.createElement('style');
        styleTag.textContent = `
            .text-glow {
                text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
                background: linear-gradient(to right, #603E8A, #3DC2EC);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        `;
        document.head.appendChild(styleTag);




    return (
        <div className='border-b bg-white border-gray-300 top-0 z-50 min-w-full absolute'>
            <div className='flex items-center pr-4 md:pr-10 lg:pr-15 xl:pr-30 2xl:pr-50'>
                <Link to='/'>
                    <div className='border-r border-gray-300 px-4 md:px-8 py-[18px] flex items-center justify-center' onClick={() => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }}>
                        <div className='flex justify-center items-center'>
                            <img className='h-[24px]' src={Logo} alt='Logo' />
                            <h1 className='font-medium text-[21px] ml-[12px]'>Superday</h1>
                        </div>
                    </div>
                </Link>
                {/* Mobile View - SignIn Button next to Hamburger */}
                <div className='md:hidden ml-auto flex items-center'>
                    <div className='ml-2' onClick={toggleMobileMenu}>
                        {/* Conditionally render the SVG for the hamburger menu or "X" */}
                        {isMobileMenuOpen ? (
                            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
                            </svg>
                        ) : (
                            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4 6h16M4 12h16m-16 6h16' />
                            </svg>
                        )}
                    </div>
                </div>
                {/* Desktop View */}
                <div className='hidden md:flex flex-1 justify-end items-center'>
                    <div className='flex space-x-9 justify-center items-center'>
                        {isAuthenticated ? (
                            <>
                            {isAdmin ? (
                                <Link to='/admin'>
                                <div className="group text-inherit transition duration-300">
                                    <span className='text-glow'>Admin</span>
                                    <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-current"></span>
                                </div>
                            </Link>
                            ) : <></>}
                            <Link to='/dashboard'>
                                <div className="group text-inherit transition duration-300">
                                    Dashboard
                                    <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-current"></span>
                                </div>
                            </Link>
                            </>
                        ) : <></>}
                        <Link to='/upgrade'>
                            <div onClick={() => {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            }} className="group text-inherit transition duration-300">
                                Pricing
                                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-current"></span>
                            </div>
                        </Link>
                        <Link to='/blog'>
                            <div className="group text-inherit transition duration-300">
                                Blog
                                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-current"></span>
                            </div>
                        </Link>
                        <Link to='/contact'>
                            <div className="group text-inherit transition duration-300">
                                Contact
                                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-current"></span>
                            </div>
                        </Link>
                        <SignInSignOutButton title='Sign In' location='nav' />
                    </div>
                </div>
            </div>

            {isMobileMenuOpen && (
                <div className='flex justify-center w-full absolute bg-[#f8fbff] mt-[1px] py-4 border-b border-black z-50'>
                    <div className='flex flex-col items-center'>
                        <Link to='/'><div onClick={toggleMobileMenu} className='pb-4'>Home</div></Link>
                        <Link to='/upgrade'><div onClick={toggleMobileMenu} className='pb-4'>Upgrade</div></Link>
                        <Link to='/blog'><div onClick={toggleMobileMenu} className='pb-4'>Blog</div></Link> {/* Added Blog link */}
                        {isAuthenticated ? <Link to='/dashboard'><div className='pb-4'>Dashboard</div></Link> : <></>}
                        {isAuthenticated ? <Link to='/settings'><div className='pb-4'>Settings</div></Link> : <></>}
                        <SignInSignOutButton title='Sign In' location='nav' />
                    </div>
                </div>
            )}
        </div>
    );
}
