import React, { useState, useEffect } from 'react';
import SelectionSection from "../selectionSection";
import MailImage from '../../images/message.svg';
import Sent from '../../images/sent.svg';
import User from '../../images/user.svg';



export default function HowItWorks() {

    const [highlightedIndex, setHighlightedIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setHighlightedIndex(prevIndex => (prevIndex + 1) % 3); // Cycle through 0, 1, 2
        }, 2500); // Change the highlighted section every 2 seconds

        return () => clearInterval(interval);
    }, []);


    return (
        <div id = 'howitworks' className='flex items-center justify-center flex-col bg-black w-full text-white py-[75px] text-center'>
            <div className='flex items-center justify-center flex-col bg-black w-full text-white'>
            </div>

            <div className="max-w-lg mb-12 max-sm:px-8 px-4 text-center lg:max-w-2xl">
                <h1 className='font-medium text-[40px] lg:text-[50px] tracking-tight leading-tight md:leading-snug lg:leading-[115%]'>How it Works</h1>
                {/* <p className='text-[16px] lg:text-[18px] opacity-70'>On average these tools can save users 30 hours or more a month</p> */}
            </div>

            <div className='flex justify-center items-center'>
            <div className='flex mx-auto flex-wrap items-start justify-center md:max-w-[80%] lg:max-w-full gap-1.25 md:gap-0'>
            <SelectionSection title='Sourcing Emails' isHighlighted={highlightedIndex === 0} image={MailImage} content="We source and validate email contacts of professionals in your industry to help get you on their good side."></SelectionSection>
                    <SelectionSection title='Auto Send' isHighlighted={highlightedIndex === 1} image={Sent} content='Send emails directly from your own inbox. Customized send times and include your resume as you wish.'></SelectionSection>
                    <SelectionSection title='Custom Email Template' isHighlighted={highlightedIndex === 2} image={User} content="Handcraft personalized emails with our unique variables tailored to each recipient."></SelectionSection>
                    <SelectionSection title='Auto Apply' isHighlighted={highlightedIndex === 2} image={Sent} content="Apply to more jobs and get in front of interviewers faster to show them your strengths and ambition."></SelectionSection>
                </div>
            </div>
        </div>
    );
}