import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import OnSuccessPopup from '../../components/OnSuccessPopup';
import OnUpgradePopup from '../../components/OnUpgradePopup';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import cookie from 'cookiejs';
import { FaLock } from 'react-icons/fa';
import InfoTooltip from '../../components/InfoTooltip'

export default function SendSettingsMobile({userSubscription}) {

    const { user, isAuthenticated } = useAuth0();
    const [autoSend, setAutoSend] = useState(false);
    const [autoSendConfirmationPopUp, setAutoSendConfirmationPopup] = useState(false);
    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [includeResume, setIncludeResume] = useState(false);
    const [selectedDay, setSelectedDay] = useState('MON');
    const [selectedTime, setSelectedTime] = useState('9:30');
    const [selectedTimezone, setSelectedTimezone] = useState(cookie.get('timezone') || 'EST');
    const [isLoading, setIsLoading] = useState(false);
    const [s3Uri, setS3Uri] = useState('');
    const [DBDay, setDBDay] = useState('MON');
    const [DBTime,setDBTime] = useState('9:30');
    const [displayTimezone, setDisplayTimezone] = useState('UTC');
    

    const { DynamoDBClient, UpdateItemCommand, GetItemCommand } = require("@aws-sdk/client-dynamodb");
    const { S3 } = require('aws-sdk');

    const awsCredentials = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    };

    const s3 = new S3({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });

    const dynamoDBClient = new DynamoDBClient({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });

    const timezoneFullNames = {
        'UTC': 'UTC',
        'EST': 'America/New_York',
        'CST': 'America/Chicago',
        'MST': 'America/Denver',
        'PST': 'America/Los_Angeles',
        'AKST': 'America/Anchorage',
        'HST': 'Pacific/Honolulu'
    };

    const timezoneAbbreviations = Object.fromEntries(
        Object.entries(timezoneFullNames).map(([key, value]) => [value, key])
    );
    

    const handleTimezoneChange = (e) => {
        const abbreviation = e.target.value;
        const newTimezone = timezoneFullNames[abbreviation];
        setSelectedTimezone(newTimezone);
        cookie.set('timezone', newTimezone, { expires: 365 });
      
        let convertedTime;
        // If the new timezone is UTC, handle conversion separately
        if (newTimezone === 'UTC') {
          // Convert the current selected time from the previous timezone to UTC
          convertedTime = moment.tz(`${selectedDay} ${selectedTime}`, 'ddd HH:mm', selectedTimezone).utc();
        } else {
          // Convert stored UTC time to the new timezone
          const utcTime = moment.tz(`${selectedDay} ${selectedTime}`, 'ddd HH:mm', 'UTC');
          convertedTime = utcTime.clone().tz(newTimezone);
        }
      
        // Update state with the new time and day
        setSelectedTime(convertedTime.format('HH:mm'));
        setSelectedDay(convertedTime.format('ddd').toUpperCase());
      };
      

    const convertTimeToUTC = () => {
        // Create the local moment for the current date and time in the selected timezone
        const localMoment = moment().tz(selectedTimezone);
    
        // Adjust the moment to the start of the week, and then add the correct number of days to reach the selected day
        localMoment.startOf('week').day(selectedDay);
    
        // Parse the selected time and add it to the moment
        const [hour, minute] = selectedTime.split(':');
        localMoment.hour(parseInt(hour, 10)).minute(parseInt(minute, 10));
    
        // Check if the datetime is in the past, if so, add a week to move to the next occurrence
        if (localMoment.isBefore(moment().tz(selectedTimezone))) {
            localMoment.add(1, 'week');
        }
    
        // Convert the local time to UTC
        const utcMoment = localMoment.utc();

        // Log final conversion results
        const finalUTCtime = utcMoment.format('HH:mm');
        const finalUTCday = utcMoment.format('ddd').toUpperCase();
    
        return {
            time: finalUTCtime, // formatted UTC time
            day: finalUTCday, // formatted UTC day of week
        };
    };
     
      
    useEffect(() => {
        const fetchUserData = async () => {
            const params = {
                TableName: "internify",
                Key: { client_email: { S: user.email } }
            };
    
            try {
                const command = new GetItemCommand(params);
                const data = await dynamoDBClient.send(command);
                if (data.Item) {
                    console.log('Data fetched from DynamoDB:', data.Item);
                    
                    setAutoSend(data.Item.auto_send.BOOL);
                    setIncludeResume(data.Item.include_resume.BOOL);
                    setS3Uri(data.Item.s3Uri || '');
    
                    const timezone = cookie.get('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
                    cookie.set('timezone', timezone, { expires: 365 }); // Ensure the cookie is always set
                    setSelectedTimezone(timezone);
    
                    // Convert time and day from UTC to the local timezone
                    const utcDayOfWeek = data.Item.auto_send_day_of_week?.S || 'MON'; // Default to 'MON' if undefined
                    const utcTimeOfDay = data.Item.auto_send_time_of_day?.S || '09:00'; // Default to '09:00' if undefined

                    setDBDay(utcDayOfWeek);
                    setDBTime(utcTimeOfDay);
    
                    const localTime = moment.tz(`${utcDayOfWeek} ${utcTimeOfDay}`, 'ddd HH:mm', 'UTC').tz(timezone);
    
                    setSelectedDay(localTime.format('ddd').toUpperCase());
                    setSelectedTime(localTime.format('HH:mm'));
                    
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
    
        if (isAuthenticated && user) {
            fetchUserData();
        }
    
        if (!cookie.get('timezone')) {
            const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            cookie.set('timezone', detectedTimezone, { expires: 365 });
            setSelectedTimezone(detectedTimezone);
        }
    
    }, [user, isAuthenticated]);
    
    

    const handleAutoSend = async (autoSendInput) => {
        const autoSend = typeof autoSendInput === 'boolean' ? autoSendInput : !!autoSendInput.autoSend;

        const params = {
            TableName: "internify",
            Key: {
                client_email: { S: user.email } // Ensure user.email is correctly defined elsewhere in your code
            },
            UpdateExpression: "set auto_send = :a",
            ExpressionAttributeValues: {
                ":a": { BOOL: autoSend }
            },
            ReturnValues: "UPDATED_NEW"
        };

        try {
            const command = new UpdateItemCommand(params);
            const response = await dynamoDBClient.send(command);

            console.log("Success", response);
            setShowSuccessMessage(true);
            setAutoSend(autoSendInput)
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            console.error("Error updating autoSend status:", error);
        }
    };

    const handleResumeInclusion = async (event, autoSendInput) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setIncludeResume(autoSendInput)
        const autoSend = typeof autoSendInput === 'boolean' ? autoSendInput : !!autoSendInput.autoSend;

        const params = {
            TableName: "internify",
            Key: {
                client_email: { S: user.email } // Ensure user.email is correctly defined elsewhere in your code
            },
            UpdateExpression: "set include_resume = :a",
            ExpressionAttributeValues: {
                ":a": { BOOL: autoSend }
            },
            ReturnValues: "UPDATED_NEW"
        };

        try {
            const command = new UpdateItemCommand(params);
            const response = await dynamoDBClient.send(command);
            console.log("Success", response);
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            console.error("Error updating autoSend status:", error);
        }
    };

    const handleDayOfWeekChange = (e) => {
        setSelectedDay(e.target.value);
    };

    const handleTimeChange = (e) => {
        setSelectedTime(e.target.value);
    };

    const handleSaveSchedule = async (e) => {
        e.preventDefault();
        const { time: utcTime, day: utcDayOfWeek } = convertTimeToUTC();
        setIsLoading(true);
        
    
        const params = {
            TableName: "internify",
            Key: {
                client_email: { S: user.email } // Ensure user.email is correctly defined
            },
            UpdateExpression: "set auto_send_day_of_week = :dow, auto_send_time_of_day = :tod",
            ExpressionAttributeValues: {
                ":dow": { S: utcDayOfWeek },
                ":tod": { S: utcTime }
            },
            ReturnValues: "UPDATED_NEW"
        };
    
        try {
            await handleAutoSend(true);

            const command = new UpdateItemCommand(params);
            const response = await dynamoDBClient.send(command);
            console.log("Success - Database updated", response);

            const apiGatewayEndpointSchedule = "https://ylqm1lgeud.execute-api.us-east-1.amazonaws.com/dev/create-schedule";

            const scheduleResponse = await fetch(apiGatewayEndpointSchedule, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    day_of_week: utcDayOfWeek,
                    time_of_day: utcTime,
                    client_email: user.email
                })
            });

            if (!scheduleResponse.ok) throw new Error(`Created schedule failed`);
            const scheduleData = await scheduleResponse.json();
            console.log(`Success - Schedule created`, scheduleData);

            setDBDay(selectedDay);
            setDBTime(selectedTime);
        } catch (error) {
            console.error("Error updating schedule:", error);
        } finally {
            setIsLoading(false);
            setAutoSendConfirmationPopup(false);
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        }
    };

    const viewResume = async () => {
        if (!s3Uri) {
            alert('No resume uploaded.');
            return;
        }

        const parts = s3Uri.S.split('/');
        const resumeFileName = parts.pop();

        const params = {
            Bucket: 'internify-resumes', // Your S3 Bucket name
            Key: `resumes/${resumeFileName}`, // Construct the file key within your bucket
            Expires: 60, // Link expiration time in seconds
        };

        try {
            const url = await s3.getSignedUrlPromise('getObject', params);
            window.open(url, '_blank'); // Open the URL in a new tab
        } catch (error) {
            console.error('Error generating signed URL:', error);
            console.log('Failed to generate resume link.');
        }
    };

    const toggleAutoSend = () => {
        if(autoSend){
            handleAutoSend(false);
        }else{
            setAutoSendConfirmationPopup(true);
        }
    };

    const handleBlocked = () => {
        setShowUpgradeMessage(true);
        setTimeout(() => setShowUpgradeMessage(false), 3000);
    }

    const formatTimeAndDay = (utcDay, utcTime, timezone) => {
        // Construct a moment object from the UTC day and time
        const utcDateTimeStr = `${utcDay} ${utcTime} UTC`;
        const utcMoment = moment.utc(utcDateTimeStr, 'ddd HH:mm UTC').tz(timezoneFullNames[timezone]);
    
        // Format the moment object to the desired format
        const formattedDay = utcMoment.format('dddd'); // Full day name
        const formattedTime = utcMoment.format('h:mm A'); // Time in 12-hour format with AM/PM
    
        return `${formattedDay} ${formattedTime}`;
    };    
    
    

    return (
        <div className='bg-white p-8 border border-gray-300 rounded-2xl mb-44 h-full'>

            {
                showUpgradeMessage ? (
                    <OnUpgradePopup showMessage={showUpgradeMessage} message={'You need to be a Pro member to access this feature.'}></OnUpgradePopup>
                ) : null
            }

            {
                autoSendConfirmationPopUp && (
                    <div className='fixed inset-0 bg-black bg-opacity-75 z-[269] flex justify-center items-center overflow-x-hidden'>
                    <div className='relative px-12 py-10 bg-white rounded-xl max-w-sm'>
                        <button 
                            className='absolute top-4 right-4 text-black text-3xl'
                            onClick={() => {setAutoSendConfirmationPopup(false)}}
                        >
                            &times;
                        </button>
                        <h1 className='font-medium text-2xl text-center'>Auto Send</h1>
                        <h1 className="text-sm text-center mt-4 text-gray-500">
                            You are about to turn on Auto Send.<br></br> This will allow Superday to
                            send emails on your behalf using your template.
                        </h1>
                        <h2 className="text-xs text-center mt-4 text-gray-500">(Note: you may have to refresh the page to see the time on your dashboard correctly)</h2>
                        <div className='flex my-5 items-center w-full'>
                        <p className='text-gray-500 text-sm'>Set Timing</p>
                        <select
                            className="border-gray-300 text-xs border rounded-md text-gray-700 h-10 pl-3 pr-6 ml-5 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                            onChange={handleDayOfWeekChange}
                            value={selectedDay}
                        >
                            <option value="MON">Monday</option>
                            <option value="TUE">Tuesday</option>
                            <option value="WED">Wednesday</option>
                            <option value="THU">Thursday</option>
                            <option value="FRI">Friday</option>
                            <option value="SAT">Saturday</option>
                            <option value="SUN">Sunday</option>
                        </select>
                        <input
                            type="time"
                            className="border-gray-300 text-xs border rounded-md text-gray-700 h-10 px-4 ml-5 w-32"
                            value={selectedTime}
                            onChange={handleTimeChange}
                        />
                        <select
                            className="border-gray-300 text-xs border rounded-md text-gray-700 h-10 pl-3 pr-6 ml-5 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                            onChange={handleTimezoneChange}
                            value={timezoneAbbreviations[selectedTimezone]}
                        >
                            {Object.keys(timezoneFullNames).map((abbreviation) => (
                            <option key={abbreviation} value={abbreviation}>{abbreviation}</option>
                            ))}
                        </select>
                    </div>
                        <div className='flex mt-8 w-full align-center justify-center'>
                            <button 
                                className='text-sm border-black border bg-black px-5 py-2 mr-10 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out' 
                                onClick={() => {setAutoSendConfirmationPopup(false)}}
                            >
                                Not yet
                            </button>

                            <button 
                                className='text-sm border-black border bg-black px-5 py-2 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out' 
                                onClick={handleSaveSchedule}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving Schedule...' : 'Save Schedule'}
                            </button>
                            
                        </div>
                    </div>
                </div>
                )
            }

            <h1 className="font-medium text-3xl flex items-center">
                Email Preferences
                
            </h1>
            <div className='my-8'>
                <div className='flex justify-between items-center my-4'>
                    <h2 className='text-gray-500 text-sm'>Auto Send</h2>
                                {(userSubscription === "1" || userSubscription === "2") ? (
                                    <button
                                        style={{
                                            width: '70px',
                                            height: '30px',
                                            backgroundColor: autoSend ? '#4caf50' : '#f44336',
                                            border: '1px solid #ccc',
                                            borderRadius: '90px',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s ease',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            padding: '5px'
                                        }}
                                        onClick={toggleAutoSend}
                                    >
                                        <span
                                            style={{
                                                content: '',
                                                position: 'absolute',
                                                width: '20px',
                                                height: '20px',
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                                transition: 'transform 0.3s ease',
                                                top: '4px',
                                                transform: autoSend ? 'translateX(20px)' : 'translateX(-20px)',
                                            }}
                                        ></span>
                                    </button>
                                ) : (
                                    <button
                                        style={{
                                            width: '70px',
                                            height: '30px',
                                            backgroundColor: '#cccccc',
                                            border: '1px solid #000',
                                            borderRadius: '90px',
                                            cursor: 'not-allowed',
                                            opacity: '0.6',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                        onClick={handleBlocked}
                                    >
                                        <span
                                            style={{
                                                content: '',
                                                position: 'absolute',
                                                width: '24px',
                                                height: '24px',
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                                transition: 'transform 0.3s ease',
                                                top: '2px',
                                            }}
                                        ></span>
                                        <FaLock className="lock-icon" style={{ color: '#333', fontSize: '16px' }} />
                                    </button>
                                )}
                            </div>
                            {(userSubscription === "1" || userSubscription === "2") &&
                            <div className="flex items-center mt-3">
                            <p className='text-gray-500 text-sm mt-2'>Scheduled Send set for: {formatTimeAndDay(DBDay, DBTime, displayTimezone)}</p>
                            <select
                                className="border-gray-300 text-xs border rounded-md text-gray-700 h-7 pl-3 pr-6 ml-4 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                                onChange={(e) => setDisplayTimezone(e.target.value)}
                                value={displayTimezone}
                            >
                                {Object.keys(timezoneFullNames).map((abbreviation) => (
                                    <option key={abbreviation} value={abbreviation}>
                                        {abbreviation}
                                    </option>
                                ))}
                            </select>
                            </div>
                            }
                <div className='flex my-5 items-center w-full justify-between'>
                    <p className='text-gray-500 text-sm'>Your current resume</p>
                    {s3Uri != '' ? 
                    <div onClick={viewResume}>
                        <div className={`text-xs px-4 py-2 border rounded-md opacity-100 bg-black text-black bg-transparent border-black border'`}>
                            <button>View Current Resume</button>
                        </div>
                    </div>
                    : 
                    <Link
                        to='/settings/resume'
                        type="submit"
                        className={`text-xs px-4 py-2 border border-black bg-transparent text-black rounded-full hover:bg-black hover:text-white duration-300 ease-in-out`}
                    >
                        Upload resume                </Link>
                    }
                </div>

                <div className='flex-row mt-5 items-center w-full justify-between'>
                    <p className='text-gray-500 text-sm'>Attach Resume</p>
                    <div>
                        <button
                            onClick={(e) => handleResumeInclusion(e, true)}
                            type="submit"
                            className={`text-xs px-4 py-2 border rounded-md ${includeResume ? 'opacity-100 bg-black text-black bg-transparent border-black border' : 'text-gray-400 border-gray-300'} mr-4`}
                        >
                            Include resume                   </button>
                        <button
                            onClick={(e) => handleResumeInclusion(e, false)}
                            type="submit"
                            className={`text-xs px-4 py-2 border rounded-md mt-3 ${!includeResume ? 'opacity-100 bg-black text-black bg-transparent border-black border' : 'text-gray-400 border-gray-300'}`}
                        >
                            Don't include                </button>
                    </div>
                </div>
            </div>

            {
                showSuccessMessage ? (
                  <div className="">
                    <OnSuccessPopup showSuccessMessage={showSuccessMessage} successMessage={'Your profile has been updated.'}></OnSuccessPopup>
                  </div>
                    
                ) : null
            }
        </div>
    )
}