import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider, Route,
  Navigate
} from "react-router-dom";
import Home from './pages/home/home';
import UpgradePage from './pages/upgrade/upgrade';
import SignUp from './pages/signup/SignUp';
import { Auth0Provider } from '@auth0/auth0-react';
import ReactGA from "react-ga4";
import Dashboard from './pages/dashboard/dashboard';
import EditInfo from './components/dashboard/editInfo';
import UpdatePage from './pages/settings/settings';
import TOS from './pages/tos/tos';
import Privacy from './pages/privacy/privacy';
import Contact from './pages/contact/contact';
import AutoApply from './pages/autoapply/autoapply';
import Nav from './pages/nav/nav'; // Update the path to Nav
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import { TourProvider, useTour } from '@reactour/tour'; // Ensure useTour and TourProvider are imported
import steps from './utils/tourSteps'; // Ensure steps are correctly imported
import tourUtils from './utils/tourUtils'; // Ensure tourUtils are correctly imported
import './index.css';
import Admin from './pages/admin/admin';

global.curr_client_email = "";
ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS);

ReactGA.send({ hitType: "pageview", page: window.location.pathname, });

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<Home />} />
      <Route path="/upgrade" element={<UpgradePage />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/onboard" element={<EditInfo />} />
      <Route path="settings" element={<Navigate replace to="/settings/basicInfo" />} />
      <Route path="settings/:settingName" element={<UpdatePage />} />
      <Route path="/tos" element={<TOS />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/autoapply" element={<AutoApply />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:id" element={<BlogPost />} />
      <Route path="/admin" element={<Admin />} />
    </Route>
  )
);

const ScrollManager = () => {
  const { currentStep } = useTour();

  useEffect(() => {
    console.log('Current step:', currentStep);
    if (currentStep == null || currentStep == undefined) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      console.log('Scrolling disabled');
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      console.log('Scrolling enabled');
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    };
  }, [currentStep]);

  return null;
};

const rootElement = document.getElementById("root");
if(rootElement){
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        redirectUri={window.location.origin + '/dashboard'}
        authorizationParams={{
          redirect_uri: window.location.origin + '/dashboard'
        }}
      >
        <TourProvider steps={steps} components={tourUtils}
          closeWithMask={true} disableKeyboardNavigation={true}>
          <RouterProvider router={router} />
        </TourProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
}


reportWebVitals();
