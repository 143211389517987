import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaLock } from 'react-icons/fa';
import '../autoapply/autoApplyView.css';
import OnUpgradePopup from '../../components/OnUpgradePopup';
import AutoApplyContent from './autoApplyContent';
import { updateAutoApplyStatus } from '../../components/awsAuth';
import InfoTooltip from '../../components/InfoTooltip';

const AutoApplyView = ({ userEmail, userSubscription, userAutoApply, userJobHistory }) => {
    const [autoApply, setAutoApply] = useState(userAutoApply || false);
    const [showAutoApplyConfirmationPopUp, setAutoApplyConfirmationPopUp] = useState(false);
    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);

    const toggleAutoApply = () => {
        if (autoApply) {
            setAutoApply(prevState => !prevState);
            updateAutoApplyStatus(userEmail, false);
        } else {
            setAutoApplyConfirmationPopUp(true);
        }
    };

    const handlePopup = () => {
        setAutoApplyConfirmationPopUp(false);
        setAutoApply(true);
        updateAutoApplyStatus(userEmail, true);
    };

    const handleBlocked = () => {
        setShowUpgradeMessage(true);
        setTimeout(() => setShowUpgradeMessage(false), 3000);
    };

    return (
        <div className="auto-apply-wrapper">
            {showAutoApplyConfirmationPopUp && (
                <div className='fixed inset-0 bg-black bg-opacity-75 z-[269] flex justify-center items-center'>
                    <div className='relative px-12 py-10 bg-white rounded-xl'>
                        <button
                            className='absolute top-4 right-4 text-black text-3xl'
                            onClick={() => { setAutoApplyConfirmationPopUp(false) }}
                        >
                            &times;
                        </button>
                        <h1 className='font-medium text-2xl text-center'>Auto Apply</h1>
                        <h1 className="text-md text-center mt-4 text-gray-500">
                            You are about to turn on Auto Apply. This will allow Superday to
                            <br />
                            send applications to companies on your behalf.
                        </h1>
                        <div className='flex mt-8 w-full align-center justify-center'>
                            <button
                                className='border-black border bg-black px-8 py-2 mr-10 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out'
                                onClick={() => { setAutoApplyConfirmationPopUp(false) }}
                            >
                                Not yet
                            </button>

                            <button
                                className='border-black border bg-black px-8 py-2 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out'
                                onClick={handlePopup}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showUpgradeMessage ? (
                <OnUpgradePopup showMessage={showUpgradeMessage} message={'You need to be a Premium member to access this feature.'}></OnUpgradePopup>
            ) : null}

            <div className="title-container">

            <div className="flex items-center">
            <h2 className="auto-apply-title">
                Auto Apply{userJobHistory.size > 0 ? ` (${userJobHistory.size})` : ''}
            </h2>
            <InfoTooltip text={"Auto Apply will apply to jobs in your industry with your information.\nThe number of jobs will vary based on new openings daily not including weekends."} />
            </div>

                <div className="toggle-button-wrapper">
                    {userSubscription === "2" ? (
                        <>
                            <Link to='/settings' className='text-md hover:text-gray-600 text-gray-400 p-2 rounded-md transition duration-300 mr-2'>Edit Application</Link>
                            <button
                                className={`toggle-button ${autoApply ? 'on' : 'off'}`}
                                onClick={toggleAutoApply}
                            ></button>
                        </>
                    ) : (
                        <>
                            <Link to='/settings' className='text-md text-gray-400 p-2 rounded-md transition duration-300 mr-2'>Edit Application</Link>
                            <button className="toggle-button disabled" onClick={handleBlocked}>
                                <FaLock className="lock-icon" />
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="auto-apply-container">
                <AutoApplyContent userJobHistory={userJobHistory} />
            </div>
        </div>
    );
};

export default AutoApplyView;
