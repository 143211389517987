import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}/dashboard`
    }).catch(error => {
      alert('Login failed');
      console.error('Login failed:', error);
    });
  };

  // Default styles for the navigation area
  const navStyles = "border px-16 py-2 border-black text-black text-md rounded-full";
  const navStylesMobile = "px-16 text-black text-md rounded-full";
  // Default styles for the landing page
  const landingStyles = `border border-black bg-black text-white max-sm:px-24 max-sm:py-3 max-sm:text-sm max-sm:mx-auto max-sm:ml-8 px-16 py-3 font-medium text-md rounded-full hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out sm:ml-0`;

  // White theme styles
  const whiteTheme = `px-${props.buttonWidth} py-3 border border-white rounded-full text-black text-md bg-white hover:bg-transparent hover:text-white transition-colors duration-500 ease-in-out`;

  // Conditional styles based on location and buttonTheme
  let buttonStyles;
  if (props.location === 'nav') {
    if (window.innerWidth < 600) {
      buttonStyles = navStylesMobile;
    } else {
      buttonStyles = navStyles;
    }

  } else if (props.buttonTheme === 'white') {
    buttonStyles = whiteTheme;
  } else {
    buttonStyles = landingStyles;
  }

  return (
    <button
      className={buttonStyles}
      onClick={handleLogin}
    >
      {props.title}
    </button>
  );
};

export default LoginButton;